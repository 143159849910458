/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from 'react';

export default function useCopyValue<Value>(value: Value) {
  const ref = useRef<Value>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  // возвращать именно ref, не ref.current
  return ref;
}
