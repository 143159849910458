import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';

import styles from './styles.module.scss';

const ButtonLink: FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = (props) => {
  const { className, type = 'button', ...rest } = props;

  return (
    <button
      type={
        type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'
      }
      className={classNames(styles.button, className)}
      {...rest}
    />
  );
};

export default ButtonLink;
