/* eslint-disable import/no-cycle */
import { FiltersDataType } from '@containers/LINK/LData/lFilterData';

const STORAGE_KEYS = {
  SBBOL_RETURN_TO: 'sbbolReturnTo',
  CREDENTIALS: 'credentials',
  EXIT_POINT: 'exitPoint',
  ENTRANCE: 'entrance',
  CHAT_INFO: 'chatInfo',
  IS_MENU_OPEN: 'isMunuOpen',
  FILTERS_VALUE: 'filtersValue',
};

enum ENTER_POINTS {
  TAXFREE = 'TAXFREE',
  LINK = 'LINK',
}

export const exitRouteMap: Record<string, string> = {
  [ENTER_POINTS.TAXFREE]: '/login',
  [ENTER_POINTS.LINK]: '/link/login',
};

const DISABLE = 'DISABLE';

const { CREDENTIALS, EXIT_POINT, IS_MENU_OPEN, FILTERS_VALUE } = STORAGE_KEYS;

export function getStorageValue<Value>(key: string): Value | null {
  try {
    const storageValue = localStorage.getItem(key);
    return storageValue ? JSON.parse(storageValue) : null;
  } catch (e) {
    console.error('Ошибка парсинга localStorage', e);
  }
  return null;
}

export function getSessionValue<Value>(key: string): Value | null {
  const storageValue = sessionStorage.getItem(key);
  return storageValue ? JSON.parse(storageValue) : null;
}

export const setStorageValue = (key: string, value: unknown): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const setSessionValue = (key: string, value: unknown): void =>
  sessionStorage.setItem(key, JSON.stringify(value));

export const removeCredentials = (): void =>
  localStorage.removeItem(CREDENTIALS);

export const setCredentials = (value: string): void =>
  localStorage.setItem(CREDENTIALS, value);

export const getCredentials = (): string | null =>
  localStorage.getItem(CREDENTIALS);

export const setExitPointTAXFREE = (): void =>
  localStorage.setItem(EXIT_POINT, ENTER_POINTS.TAXFREE);

export const setExitPointLINK = (): void =>
  localStorage.setItem(EXIT_POINT, ENTER_POINTS.LINK);

export const getExitPoint = (): string => {
  const exitPointValue = localStorage.getItem(EXIT_POINT);
  return exitPointValue ? exitRouteMap[exitPointValue] : '/login';
};

export const setEntrancePoint = (): void =>
  localStorage.setItem(STORAGE_KEYS.ENTRANCE, window.location.pathname);

export const getEntrancePoint = (): string | null =>
  localStorage.getItem(STORAGE_KEYS.ENTRANCE);

export const clearEntrancePoint = (): void =>
  localStorage.removeItem(STORAGE_KEYS.ENTRANCE);

export const showChatInfo = (): boolean =>
  localStorage.getItem(STORAGE_KEYS.CHAT_INFO) !== DISABLE;

export const disableChatInfo = (): void =>
  localStorage.setItem(STORAGE_KEYS.CHAT_INFO, DISABLE);

export const hasMenuShown = (): boolean => !!getSessionValue(IS_MENU_OPEN);

export const setIsMenuOpen = (isOpen: boolean): void =>
  setSessionValue(IS_MENU_OPEN, isOpen);

export const switchIsMenuOpen = (): void => {
  return getSessionValue(IS_MENU_OPEN)
    ? setSessionValue(IS_MENU_OPEN, false)
    : setSessionValue(IS_MENU_OPEN, true);
};

export const removeIsMenuOpen = (): void =>
  sessionStorage.removeItem(IS_MENU_OPEN);

export const setFiltersValue = (filtersValue: FiltersDataType): void => {
  setStorageValue(FILTERS_VALUE, filtersValue);
};

export const getFiltersValue = (): FiltersDataType | null =>
  getStorageValue(FILTERS_VALUE);

export const removeFiltersValue = (): void =>
  localStorage.removeItem(FILTERS_VALUE);
