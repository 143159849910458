/* eslint-disable import/no-cycle */
import { configure, makeAutoObservable } from 'mobx';

import CurrentUserStore from './current-user-store';
import FilterTableStore from './filters-table-store';
import GenericEntitiesStore from './generic-entities-store';
import NotificationStore from './notification-store';
import RegistryReceived from './registry-received';
import ScannerStore from './scanner-store';
import SearchingStore from './searching-store';
import TableStore from './table-store';
import UiStore from './ui-store';
import VoucherEdit from './voucher-edit-store';
import VoucherStore from './voucher-store';
import VouchersPaid from './vouchers-paid-store';
import VouchersRefunds from './vouchers-refund-store';

configure({
  useProxies: 'never',
});

export class RootStore {
  public registryReceived: RegistryReceived;
  public vouchersRefunds: VouchersRefunds;
  public voucherEdit: VoucherEdit;
  public vouchersPaid: VouchersPaid;
  public filtersTable: FilterTableStore;
  public ui: UiStore;
  public currentUser: CurrentUserStore;
  public voucher: VoucherStore;
  public genericEntities: GenericEntitiesStore;
  public scanner: ScannerStore;
  public tableStore: TableStore;
  public searching: SearchingStore;
  public notification: NotificationStore;

  constructor() {
    makeAutoObservable(this, {
      ui: false,
      voucher: true,
      genericEntities: true,
      currentUser: false,
      tableStore: false,
    });
    this.registryReceived = new RegistryReceived(this);
    this.genericEntities = new GenericEntitiesStore(this);
    this.vouchersRefunds = new VouchersRefunds(this);
    this.vouchersPaid = new VouchersPaid(this);
    this.ui = new UiStore(this);
    this.voucher = new VoucherStore(this);
    this.scanner = new ScannerStore(this);
    this.searching = new SearchingStore(this);
    this.tableStore = new TableStore(this);
    this.filtersTable = new FilterTableStore(this);
    this.notification = new NotificationStore(this);
    this.voucherEdit = new VoucherEdit(this);
    // Should be the last
    this.currentUser = new CurrentUserStore(this);
  }
}

export default new RootStore();
