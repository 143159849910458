/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import SpinnerIcon from '@common/SpinnerIcon';
import { ButtonVariant } from '@store/entities/ui';
import classNames from 'classnames';
import { forwardRef, memo, ReactNode, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

interface Params {
  customId?: string;
}

export const enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export interface Props {
  variant: ButtonVariant;
  type?: ButtonType;
  disabled?: boolean;
  className?: string;
  to?: string;
  href?: string;
  target?: '_blank' | '_self';
  size?: 'md' | 'lg' | 'xl';
  onClick?: (e: any, params: Params) => void;
  fullWidth?: boolean;
  isLoading?: boolean;
  name?: string;
  id?: string;
  customId?: string;
  children?: ReactNode;
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      variant,
      type = ButtonType.BUTTON,
      disabled,
      className,
      to,
      href,
      target,
      children,
      size = 'md',
      onClick,
      fullWidth,
      isLoading,
      name,
      id,
      customId,
    },
    ref,
  ) => {
    const classNameCalculated = classNames(
      styles.button,
      {
        [styles.buttonLoading]: isLoading,
        [styles.buttonFullWidth]: fullWidth,
        [styles.buttonMd]: size === 'md',
        [styles.buttonLg]: size === 'lg',
        [styles.buttonXl]: size === 'xl',
        [styles.buttonDisabled]: disabled,
        [styles.buttonDanger]: variant === 'danger',
        [styles.buttonDangerDisabled]: disabled && variant === 'danger',
        [styles.buttonGeneral]: variant === 'general',
        [styles.buttonGeneralDisabled]: disabled && variant === 'general',
        [styles.buttonSecondary]: variant === 'secondary',
        [styles.buttonSecondaryLoading]: isLoading && variant === 'secondary',
        [styles.buttonSecondaryMd]: size === 'md' && variant === 'secondary',
        [styles.buttonSecondaryLg]: size === 'lg' && variant === 'secondary',
        [styles.buttonSecondaryXl]: size === 'xl' && variant === 'secondary',
        [styles.buttonSecondaryDisabled]: disabled && variant === 'secondary',
      },
      className,
    );

    const content = useMemo(() => {
      if (isLoading) {
        return (
          <>
            <span className={styles.hidden}>{children}</span>
            <SpinnerIcon className={styles.spinner} />
          </>
        );
      }
      return children;
    }, [children, isLoading]);

    const handleClick = useCallback(
      (e) => {
        onClick?.(e, { customId });
      },
      [onClick, customId],
    );

    const commonProps = {
      ref: ref as any,
      id,
      onClick: handleClick,
      name,
      className: classNameCalculated,
    };

    if (to) {
      return (
        <Link {...commonProps} to={to} target={target}>
          {content}
        </Link>
      );
    }
    if (href) {
      return (
        <a {...commonProps} href={href} target={target}>
          {content}
        </a>
      );
    }
    return (
      // eslint-disable-next-line react/button-has-type
      <button {...commonProps} type={type} disabled={disabled}>
        {content}
      </button>
    );
  },
);

export default memo(Button);
