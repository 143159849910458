/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  doNotShowPaymentDate,
  operatorRoles,
  showPaymentDate,
} from '@consts/users';
import { LinkUserInfoType } from '@link/LData/lTypes';
import { HttpClient } from '@services/api/http-client';
import {
  IUserAPIUserData,
  UserSettings,
  UserSettingsChangeResponse,
} from '@services/api/types/users';
import {
  getCredentials,
  hasMenuShown,
  removeCredentials,
  removeIsMenuOpen,
  setCredentials,
  setExitPointLINK,
  setExitPointTAXFREE,
  setIsMenuOpen,
} from '@services/utils/storage';
import { Credentials } from '@store/entities/common';
import { CompanyGroupType, ICompanyGroup } from '@store/entities/companyGroups';
import { ICurrentUserInfo } from '@store/entities/current-user';
import { IssuerPointAPI } from '@store/entities/issuers';
import { IRefundPointAPI } from '@store/entities/refund';
import { EUserRole, LoginErrorType } from '@store/entities/users';
import { NOTIFICATION_TYPE } from '@store/notification-store';
import { makeAutoObservable } from 'mobx';

import { getStringWithFilteredSpace } from '../services/utils/common';
import type { RootStore } from './index';

const {
  ROLE_SBER_TAX_FREE_ADMIN,
  ROLE_CLIENT_CASHIER,
  ROLE_SQS_MODERATOR,
  ROLE_SQS_AGENT,
  ROLE_SQS_CLIENT,
  ROLE_SQS_MODERATOR_ADMIN,
} = EUserRole;

export default class CurrentUserStore {
  currentUser: ICurrentUserInfo | null = null;

  linkUser: LinkUserInfoType | null = null;

  settings: UserSettings = {
    activeIssuerId: '',
    activeRefundOfficeId: '',
  };

  pointChanged = false; // сменился ли пункт выписки или возврата

  currentUserSettings: { [name: string]: any } = {};

  roles: string[] | null = null;

  hasToken = false;

  loginError: LoginErrorType = LoginErrorType.NONE;

  _isReadyToInitialize = false;

  shouldChangePassword = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    const credentials = getCredentials();
    if (credentials) {
      this.hasToken = true;
      this.linkAuth();
    }
  }

  linkAuth = async (): Promise<void> => {
    try {
      const { data } =
        await HttpClient.getAxios<LinkUserInfoType>('/sqs/users/me');
      const userRole = data.roles;

      if (userRole.length > 0) {
        this.linkUser = data;
        this.shouldChangePassword = data.shouldChangePassword;
        setExitPointLINK();
      } else {
        this.taxFreeAuth();
      }
      if (hasMenuShown() === null) setIsMenuOpen(true);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        this.setLoginError(LoginErrorType.UNAUTHORIZED);
      } else {
        this.taxFreeAuth();
        this.setLoginError(LoginErrorType.UNKNOWN);
      }
    }
  };

  taxFreeAuth = async (): Promise<void> => {
    try {
      const [userInfo, settings] = await Promise.all([
        HttpClient.getAxios<IUserAPIUserData>('/users/me'),
        HttpClient.getAxios<UserSettings>('/settings', {
          params: {
            name: ['activeRefundOfficeId', 'activeIssuerId'],
          },
        }),
      ]);

      if (userInfo.data.groups.length === 0) {
        this.linkAuth();
      } else if (userInfo.data.userInfo?.status === 'deactive') {
        this.logout();
        this.loginError = LoginErrorType.USER_DISABLED;
        throw new Error('Эта учетная запись не активна');
      } else {
        this.currentUser = userInfo.data;
        this.shouldChangePassword = userInfo.data.shouldChangePassword;
        this.settings = settings.data;
        setExitPointTAXFREE();

        if (!userInfo.data.shouldChangePassword) {
          await this.rootStore.genericEntities.fetchUsersRoles(
            userInfo.data.groups?.[0]?.roles[0],
          );
        }

        this.isReadyToInitialize = !userInfo.data.shouldChangePassword;
        this.reInitialize();
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error: unknown) {
      this.logout();
    }
  };

  // АВТОРИЗАЦИЯ ТАКФРИИ
  // АВТОРИЗАЦИЯ ТАКФРИИ
  // АВТОРИЗАЦИЯ ТАКФРИИ
  login = async (loginRaw: string, password: string): Promise<void> => {
    const login = getStringWithFilteredSpace(loginRaw);
    try {
      const { data } = await HttpClient.postAxios<Credentials>(
        '/authentication_token',
        {
          login,
          password,
        },
      );

      this.loginError = LoginErrorType.NONE;
      this.hasToken = true;
      setCredentials(data.token);
      this.taxFreeAuth();
    } catch (error: any) {
      if (error?.response?.status === 401) {
        this.setLoginError(LoginErrorType.UNAUTHORIZED);
      } else {
        this.setLoginError(LoginErrorType.UNKNOWN);
      }
    }
  };

  // АВТОРИЗАЦИЯ ЛИНК
  // АВТОРИЗАЦИЯ ЛИНК
  // АВТОРИЗАЦИЯ ЛИНК
  linkLogin = async (loginRaw: string, password: string): Promise<void> => {
    const login = getStringWithFilteredSpace(loginRaw);
    try {
      const { data } = await HttpClient.postAxios<Credentials>(
        '/authentication_token',
        {
          login,
          password,
        },
      );

      this.loginError = LoginErrorType.NONE;
      this.hasToken = true;
      setCredentials(data.token);

      this.linkAuth();
    } catch (error: any) {
      if (error?.response?.status === 401) {
        this.setLoginError(LoginErrorType.UNAUTHORIZED);
      } else {
        this.setLoginError(LoginErrorType.UNKNOWN);
      }
      this.logout();
    }
  };

  changePassword = async (plainPassword: string): Promise<boolean> => {
    let result = false;
    try {
      const { data } = await HttpClient.patchAxios<IUserAPIUserData>(
        `/users/${this.userId}/change_password`,
        {
          plainPassword,
        },
      );
      this.rootStore.genericEntities.fetchUsersRoles();
      this.setCurrentUser(data);
      this.shouldChangePassword = data.shouldChangePassword;
      this.isReadyToInitialize = !data.shouldChangePassword;
      this.reInitialize();
      this.rootStore.notification.open(
        'Пароль был изменен.',
        NOTIFICATION_TYPE.success,
        [],
      );
      result = true;
    } catch (error: any) {
      HttpClient.log(error, {
        method: 'PATCH',
        component: 'CurrentUserStore',
        functionName: 'changePassword',
        codeLine: 221,
        requestUrl: `/users/${this.userId}/change_password`,
        request: {
          plainPassword,
        },
      });

      this.rootStore.notification.handleResponseError(error);
    }
    return result;
  };

  reInitialize = (): void => {
    if (this.isReadyToInitialize) {
      switch (this.role) {
        case ROLE_SBER_TAX_FREE_ADMIN: {
          this.rootStore.genericEntities.initialize();
          break;
        }
        case ROLE_CLIENT_CASHIER: {
          this.rootStore.genericEntities.cashierInitialize();
          break;
        }
        case ROLE_SQS_AGENT: {
          this.shouldChangePassword = false;
          this.linkAuth();
          break;
        }
        case ROLE_SQS_CLIENT: {
          this.shouldChangePassword = false;
          this.linkAuth();
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  acceptAgreement = async (): Promise<void> => {
    try {
      await HttpClient.postAxios('sqs/users/accept_agreement');
      if (this.linkUser) {
        this.linkUser.hasAgreementAccepted = true;
      }
    } catch (e: any) {
      HttpClient.log(e, {
        method: 'POST',
        component: 'CurrentUserStore',
        functionName: 'acceptAgreement',
        codeLine: 272,
        requestUrl: 'sqs/users/accept_agreement',
      });
      this.rootStore.notification.handleSimpleError(
        'Что-то пошло нет так. Повторите попытку еше раз.',
      );
    }
  };

  subscription = async (token: string): Promise<void> => {
    try {
      this.loginError = LoginErrorType.NONE;
      this.hasToken = true;
      setCredentials(token);
      await this.taxFreeAuth();
    } catch (error: unknown) {
      HttpClient.log(error, {
        component: 'CurrentUserStore',
        functionName: 'subscription',
        codeLine: 291,
      });
    }
  };

  updateUserAsync = async (): Promise<boolean> => {
    try {
      const user = await HttpClient.getAxios<IUserAPIUserData>('/users/me');
      this.currentUser = user.data;
      return true;
    } catch (error: any) {
      HttpClient.log(error, {
        component: 'CurrentUserStore',
        functionName: 'updateUserAsync',
        codeLine: 305,
        requestUrl: '/users/me',
        method: 'GET',
      });
      this.rootStore.notification.handleResponseError(error);
      return false;
    }
  };

  setCurrentUser = (user: ICurrentUserInfo): void => {
    this.currentUser = user;
  };

  updateSettings = (data: UserSettingsChangeResponse): void => {
    this.settings[data.name] = data.value;
  };

  logout = (): void => {
    this.clear();
    this.rootStore.genericEntities.clear();
    this.rootStore.scanner.clear();
    this.rootStore.scanner.unsubscribe();
    this.rootStore.ui.setMenuVisible(false);
  };

  clear = (): void => {
    this.linkUser = null;
    this.pointChanged = false;
    this.hasToken = false;
    this.currentUser = null;
    this.currentUserSettings = {};
    this.isReadyToInitialize = false;
    this.shouldChangePassword = false;
    removeCredentials();
    removeIsMenuOpen();
  };

  setLoginError = (error: LoginErrorType): void => {
    this.loginError = error;
  };

  handlePointChange = (value: boolean): void => {
    this.pointChanged = value;
  };

  get personID(): number {
    return this.linkUser?.idTf || 0;
  }

  get role(): EUserRole | null {
    return (
      this.currentUser?.groups?.[0]?.roles[0] || this.linkUser?.roles[0] || null
    );
  }

  get agreementAccepted(): boolean {
    return this.linkUser?.hasAgreementAccepted || false;
  }

  get isLinkModeratorRole(): boolean {
    return (
      !!this.linkUser?.roles.includes(ROLE_SQS_MODERATOR) ||
      !!this.linkUser?.roles.includes(ROLE_SQS_MODERATOR_ADMIN)
    );
  }

  get isLinkModeratorADMIN(): boolean {
    return !!this.linkUser?.roles.includes(ROLE_SQS_MODERATOR_ADMIN);
  }

  get taxFreeAdmin(): boolean {
    return this.role === ROLE_SBER_TAX_FREE_ADMIN;
  }

  get notTaxFreeAdmin(): boolean {
    return this.role !== ROLE_SBER_TAX_FREE_ADMIN;
  }

  get roleName(): string {
    return this.currentUser?.groups?.[0]?.name || '';
  }

  get isCashierAndVoucherAgent(): boolean {
    return (
      this.role === ROLE_CLIENT_CASHIER &&
      this.currentUser?.companyGroup?.type === CompanyGroupType.VOUCHER_AGENT
    );
  }

  get isCompanyGroupReteail(): boolean {
    return this.currentUser?.companyGroup?.type === CompanyGroupType.RETAIL;
  }

  get isCompanyGroupVoucherAgent(): boolean {
    return (
      this.currentUser?.companyGroup?.type === CompanyGroupType.VOUCHER_AGENT
    );
  }

  get isCompanyGroupRefundAgent(): boolean {
    return (
      this.currentUser?.companyGroup?.type === CompanyGroupType.REFUND_AGENT
    );
  }

  get roleId(): string {
    return this.currentUser?.groups?.[0]?.id || '';
  }

  get userId(): string | number | null {
    return this.currentUser?.id || this.linkUser?.idTf || null;
  }

  get currentRefundOffice(): IRefundPointAPI | null {
    const refundOffices = this.currentUser?.refundOffices || [];
    if (this.settings?.activeRefundOfficeId) {
      return (
        refundOffices.find(
          (office) => office.id === this.settings?.activeRefundOfficeId,
        ) || null
      );
    }
    return refundOffices[0] || null;
  }

  get currentIssuerPoint(): IssuerPointAPI | null {
    const issuers = this.currentUser?.issuers || [];
    if (this.settings?.activeIssuerId) {
      return (
        issuers.find((office) => office.id === this.settings?.activeIssuerId) ||
        null
      );
    }
    return issuers[0];
  }

  get companyGroup(): ICompanyGroup | undefined {
    return this.currentUser?.companyGroup;
  }

  get companyGroupId(): string | undefined {
    return this.currentUser?.companyGroup?.id;
  }

  get isReadyToInitialize(): boolean {
    return this._isReadyToInitialize;
  }

  set isReadyToInitialize(value: boolean) {
    this._isReadyToInitialize = value;
  }

  set setIsScanChecked(value: boolean) {
    const issuer = this.currentIssuerPoint;
    if (issuer) {
      issuer.isScanChecked = value;
    }
  }

  set setIsScanCheckSuccess(value: boolean | null) {
    const issuer = this.currentIssuerPoint;
    if (issuer) {
      issuer.isScanCheckSuccess = value;
    }
  }

  get isSomeOperatorRole(): boolean {
    return this.role ? operatorRoles.includes(this.role) : false;
  }

  get allowShowPaymentDate(): boolean {
    if (this.role) {
      if (showPaymentDate.has(this.role)) return true;
      if (doNotShowPaymentDate.has(this.role)) return false;
    }
    return false;
  }
}
