/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx';

import { HttpClient } from '../services/api/http-client';
import CancelableRequest from '../services/CancelableRequest';
import { getVouchersPaidParams } from '../services/utils/voucher';
import { QUERY_PARAMS } from './entities/table';
import { VoucherInterface } from './entities/voucher';
import type { RootStore } from './index';

export default class VouchersPaidStore {
  items: VoucherInterface[] = [];

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  getItemsAsync = CancelableRequest.create(async (source) => {
    this.rootStore.tableStore.setLoadingSection('vouchersCashierPaid', true);
    try {
      const sort = this.rootStore.tableStore.getCurrentSortSection(
        'vouchersCashierPaid',
      );
      const params: any = {
        [QUERY_PARAMS.page]:
          this.rootStore.tableStore.sections.vouchersCashierPaid.pagination
            .currentPageNumber,
        [QUERY_PARAMS.limit]:
          this.rootStore.tableStore.sections.vouchersCashierPaid.pagination
            .rowLimit,
        ...getVouchersPaidParams(
          this.rootStore.filtersTable.filters.vouchersCashierPaid,
        ),
      };
      if (sort) {
        params[`order[${sort.fieldKey}]`] = sort.direction;
      }
      const response = await HttpClient.getList<VoucherInterface[]>(
        '/vouchers',
        {
          cancelToken: source.token,
          params,
        },
      );
      this.setItems(response.data);
      this.rootStore.tableStore.setTotalSection(
        'vouchersCashierPaid',
        response.total,
      );
      this.rootStore.tableStore.setLoadingSection('vouchersCashierPaid', false);
    } catch (e: any) {
      HttpClient.log(e, {
        component: 'VouchersPaidStore',
        functionName: 'getItemsAsync',
        codeLine: 41,
        requestUrl: '/vouchers',
        request: {
          [QUERY_PARAMS.page]:
            this.rootStore.tableStore.sections.vouchersCashierPaid.pagination
              .currentPageNumber,
          [QUERY_PARAMS.limit]:
            this.rootStore.tableStore.sections.vouchersCashierPaid.pagination
              .rowLimit,
          ...getVouchersPaidParams(
            this.rootStore.filtersTable.filters.vouchersCashierPaid,
          ),
        },
        method: 'GET',
      });

      if (CancelableRequest.isCancelledByOuterCall(e)) {
        this.rootStore.tableStore.setLoadingSection(
          'vouchersCashierPaid',
          false,
        );
      } else if (!CancelableRequest.isCancelledByNextRequest(e)) {
        this.rootStore.notification.handleResponseError(e);
        this.rootStore.tableStore.setLoadingSection(
          'vouchersCashierPaid',
          false,
        );
      }
    }
  });

  setItems = (items: VoucherInterface[]): void => {
    this.items = items;
  };
}
