export const formatPhoneInternational = (value?: string): string => {
  if (value && !value.startsWith('+')) {
    return `+${value}`;
  }
  return value || '';
};

export const formatPhone = (phone?: string): string => {
  if (!phone) {
    return '';
  }
  const formattedPhone = `${phone.replace(/\D/g, '').slice(1)}`;
  return `+7 ${formattedPhone.slice(0, 3)} ${formattedPhone.slice(3, 6)}-${formattedPhone.slice(6, 10)}`;
};

export const formatPhoneToServer = (phone?: string): string => {
  // только русские номера
  if (!phone) {
    return '';
  }
  const formattedPhone = `${phone.replace(/\D/g, '').slice(1)}`;
  return `7${formattedPhone}`;
};
