import { FC } from 'react';

import styles from './styles.module.scss';

interface CurrencyViewType {
  value: string;
  label: string;
}

const CurrencyView: FC<CurrencyViewType> = ({ value, label }) => {
  const { container, bold, grayText } = styles;
  return (
    <div className={container}>
      <div className={bold}>{value}</div>
      <div className={grayText}>{label}</div>
    </div>
  );
};

export default CurrencyView;
