/* eslint-disable @typescript-eslint/no-explicit-any */
import ButtonIcon from '@common/ButtonIcon';
import { ReactComponent as EyeCloseIcon } from '@icons/eye_closed.svg';
import { ReactComponent as EyeOpenIcon } from '@icons/eye_open.svg';
import {
  IInputProps,
  Input as SbBolInput,
} from '@sberbusiness/triplex/components/Input/Input';
import { isEmpty } from '@services/utils/validation';
import classNames from 'classnames';
import { ChangeEvent, FC, memo, useCallback, useState } from 'react';

import styles from './styles.module.scss';

export interface Props extends Omit<IInputProps, 'onChange' | 'className'> {
  onChange: (value: string, params: any) => void;
  className?: string;
  onBlur?: () => void;
}

const InputPassword: FC<Props> = (props) => {
  const { onChange, name, onBlur, className, value, ...rest } = props;
  const [isVisible, setVisibility] = useState(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value, { name });
    },
    [onChange, name],
  );

  const handleBlur = useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  return (
    <div className={classNames(styles.password, className)}>
      <SbBolInput
        {...rest}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        type={isVisible ? 'text' : 'password'}
      />
      {!isEmpty(value) && String(value).length > 0 && (
        <ButtonIcon
          tabIndex={-1}
          className={styles.eye}
          onClick={() => {
            setVisibility(!isVisible);
          }}
        >
          {isVisible ? <EyeOpenIcon /> : <EyeCloseIcon />}
        </ButtonIcon>
      )}
    </div>
  );
};

export default memo(InputPassword);
