/* eslint-disable import/no-cycle */
import moment, { Moment } from 'moment';

import { formatDateToValue } from './dates';
import { isCardExpirationDateValid } from './validation';

export const formatCardExpireDateForServer = (
  cardExpireDate: string,
): string | null => {
  if (!isCardExpirationDateValid(cardExpireDate)) {
    return null;
  }
  const [month, year] = cardExpireDate.split('/');
  const monthNumber = Number(month);
  const yearNumber = Number(year) + 2000;
  const dates = moment(new Date(yearNumber, monthNumber - 1, 1)).daysInMonth();
  return formatDateToValue(new Date(yearNumber, monthNumber - 1, dates));
};

export const formatDateToExpireDate = (
  date?: string | Moment | Date | null,
): string => {
  if (!date) {
    return '';
  }
  const dateObj = moment(date);
  let month = String(dateObj.month() + 1);
  if (Number(month) < 10) {
    month = `0${month}`;
  }
  return `${month}/${dateObj.year().toString().slice(2, 4)}`;
};

export const formatCreditCardNumber = (number?: string | null): string => {
  if (!number) {
    return '';
  }
  if (number.length > 8) {
    return `${number.slice(0, 4)} ** ${number.slice(-4)}`;
  }
  return number;
};

export const formatCreditCardNumberForServer = (
  number?: string | null,
): string => {
  if (!number) {
    return '';
  }
  return number.replace(/\D/g, '');
};
