/* eslint-disable react/no-array-index-key */
import Fade from '@common/Fade';
import { APP_BAR_HEIGHT } from '@consts/app';
import { NotificationerrorStsIcon20 } from '@sberbusiness/icons/NotificationerrorStsIcon20';
import { NotificationsuccessStsIcon20 } from '@sberbusiness/icons/NotificationsuccessStsIcon20';
import { NotificationwarningStsIcon20 } from '@sberbusiness/icons/NotificationwarningStsIcon20';
import { Notification } from '@sberbusiness/triplex/components/Notification/Notification';
import rootStore from '@store/index';
import { NOTIFICATION_TYPE } from '@store/notification-store';
import { observer } from 'mobx-react-lite';

import style from './style.module.scss';

const AppNotification = () => {
  const takeIcon = () => {
    switch (rootStore.notification.type) {
      case NOTIFICATION_TYPE.error:
        return <NotificationerrorStsIcon20 />;
      case NOTIFICATION_TYPE.success:
        return <NotificationsuccessStsIcon20 />;
      case NOTIFICATION_TYPE.warning:
        return <NotificationwarningStsIcon20 />;
      default:
        return <></>;
    }
  };

  return (
    <Fade timeout={300} in={rootStore.notification.isVisible}>
      <div
        className={style.notification}
        id="notification"
        style={{ top: APP_BAR_HEIGHT }}
      >
        <Notification
          onMouseEnter={rootStore.notification.clearTimer}
          onMouseLeave={rootStore.notification.closeByTimeout}
        >
          <Notification.Icon>{takeIcon()}</Notification.Icon>
          <Notification.Body>
            <Notification.Body.Content>
              <div className={style.wrap}>
                <p className={style.title}>{rootStore.notification.title}</p>
                {rootStore.notification.content.map((item, key) => (
                  <p key={key} className={style.value}>
                    {item}
                  </p>
                ))}
              </div>
            </Notification.Body.Content>
          </Notification.Body>
          <Notification.Close onClick={() => rootStore.notification.close()} />
        </Notification>
      </div>
    </Fade>
  );
};

export default observer(AppNotification);
