/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import {
  registriesNotEditableStatuses,
  voucherCanRefundStatuses,
  voucherCardFormInitialValues,
  voucherPaidStatuses,
  voucherRefundedStatuses,
} from '@consts/vouchers';
import { UserForm } from '@store/entities/users';
import {
  VoucherCardForm,
  VoucherInterface,
  VoucherIssuesType,
  VoucherPaymentInfo,
  VouchersFilter,
  VoucherStatus,
  VoucherStatusHistory,
} from '@store/entities/voucher';
import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';

import { VouchersCashierPaidFilter } from '../../containers/roles/cashierRefund/VouchersPaid/types';
import { VoucherPassport } from '../api/types/vouchers';
import { expandArrayKeys } from './axios-response-helpers';
import {
  formatCardExpireDateForServer,
  formatCreditCardNumberForServer,
  formatDateToExpireDate,
} from './card';
import { formatDate, getServerPeriod } from './dates';

const {
  CREATED,
  STAMPED,
  REFUNDED_TO_CARD,
  REFUNDED_BY_CASH,
  IN_PROGRESS,
  SENT_TO_BANK,
  FOR_BANK_PAY,
  REFUSAL,
  REFUSAL_BY_BANK_REJECTED,
  REFUSAL_NO_CUSTOMS_MARK,
  REFUSAL_NO_PAYMENT_METHOD,
  REFUSAL_BY_EXPORT_PERIOD,
  REFUSAL_BY_REFUND_PERIOD,
  ERROR,
  ERROR_NO_CUSTOMS_MARK,
  ERROR_NO_PAYMENT_METHOD,
  ERROR_BANK_REJECTED,
  CANCELLED_BY_REFUND_OFFICE,
  CANCELLED_BY_OPERATOR,
  CANCELLED_BY_SHOP,
  REGISTERED,
  REJECTED_BY_FCS,
} = VoucherStatus;

export const getVoucherStatusColor = (status?: VoucherStatus): string => {
  switch (status) {
    case STAMPED: {
      return '#21A19A';
    }
    case CREATED:
    case REGISTERED: {
      return '#FF9900';
    }
    case REFUSAL_BY_EXPORT_PERIOD:
    case REFUSAL_BY_REFUND_PERIOD:
    case REFUSAL_NO_CUSTOMS_MARK:
    case REFUSAL_NO_PAYMENT_METHOD:
    case REFUSAL:
    case ERROR_BANK_REJECTED:
    case REJECTED_BY_FCS: {
      return '#A80E2A';
    }
    case ERROR_NO_CUSTOMS_MARK:
    case ERROR_NO_PAYMENT_METHOD:
    case ERROR:
    case REFUSAL_BY_BANK_REJECTED: {
      return '#c11030';
    }
    case CANCELLED_BY_REFUND_OFFICE:
    case CANCELLED_BY_OPERATOR:
    case CANCELLED_BY_SHOP:
    case IN_PROGRESS:
    case FOR_BANK_PAY:
    case SENT_TO_BANK:
    case REFUNDED_TO_CARD:
    case REFUNDED_BY_CASH:
    default: {
      return '#B2B8BF';
    }
  }
};

export const isVoucherRefundable = (
  voucher?: VoucherInterface | null,
): boolean =>
  voucherCanRefundStatuses.includes(voucher?.status as VoucherStatus);

export const getVouchersPaidParams = (
  filter: VouchersCashierPaidFilter,
): any => {
  const params: any = {
    ...getServerPeriod(filter.createdAt),
    ...getServerPeriod(filter.updatedAt, 'updatedAt'),
    status: voucherRefundedStatuses,
    hrIdentifier: filter.number,
    refundOffice: {},
  };
  if (filter.refundOffice) {
    params.refundOffice.id = filter.refundOffice;
  }
  if (filter.status) {
    params.status = filter.status;
  }
  return params;
};

export const getVoucherParams = (filter: VouchersFilter): any => {
  const params: any = {
    ...getServerPeriod(filter.createdAt),
    ...getServerPeriod(filter.paidAt, 'paidAt'),
    hrIdentifier: filter.hrIdentifier,
    company: {},
    status: filter.statusArray,
    fiscalReceipts: {
      fiscalAccumulator: {},
    },
    refundOffice: {},
    issuer: {},
  };
  if (filter.fiscalIssuedAt) {
    params.fiscalReceipts.issuedAt = filter.fiscalIssuedAt;
  }
  if (filter.fiscalAccumulatorNumber) {
    params.fiscalReceipts.fiscalAccumulator.number =
      filter.fiscalAccumulatorNumber;
  }
  if (filter.fiscalTotalAmount) {
    params.fiscalReceipts.totalAmountByFns = filter.fiscalTotalAmount;
  }
  if (filter.company) {
    params.company.id = filter.company.id;
  }
  if (filter.refundOffice) {
    params.refundOffice.id = filter.refundOffice.id;
  }
  if (filter.issuer) {
    params.issuer.id = filter.issuer.id;
  }
  if (filter.status) {
    params.status = filter.status;
  }
  return params;
};

export const formatScannedVoucherIdToHrIdentifier = (
  scanned: string,
): string => {
  const isValid = /[\d,-]{1}/g.test(scanned);
  if (!isValid) {
    return '';
  }
  const reversedScanned = scanned
    .replace(/\D/g, '')
    .split('')
    .reverse()
    .join('');
  return `${reversedScanned.slice(0, 3)}-${reversedScanned.slice(3, 6)}-${reversedScanned.slice(6)}`
    .split('')
    .reverse()
    .join('');
};

export const getPassportNumber = (passport?: VoucherPassport): string =>
  passport?.number || '';

export const getVoucherPaidDate = (
  statusHistories: VoucherStatusHistory[],
): string => {
  const lastStatusHistory =
    statusHistories.length > 0
      ? statusHistories[statusHistories.length - 1]
      : null;
  const paidDate = voucherPaidStatuses.includes(
    lastStatusHistory?.newStatus as VoucherStatus,
  )
    ? lastStatusHistory?.createdAt
    : '';
  return formatDate(paidDate, { withHours: true });
};

export const mapViolationErrorsToVoucherFormError = (
  errors: any = {},
): FormikErrors<UserForm> => {
  const res: any = {
    ...errors,
    lastName: errors.consumer?.lastName,
    firstName: errors.consumer?.firstName,
    fatherName: errors.consumer?.fatherName,
  };
  if (typeof errors.consumer?.passport === 'string') {
    res.passportNumber = errors.consumer?.passport;
  } else {
    res.passportNumber = errors.consumer?.passport?.number;
  }
  return expandArrayKeys(res) as FormikErrors<UserForm>;
};

export const isVoucherHrIdentifierValid = (hrIdentifier: string): boolean =>
  hrIdentifier.replace(/_/g, '').length === 13;

export const isVoucherHrIdentifier = (hrIdentifier: string): boolean =>
  hrIdentifier.replace(/^\d[5]-\d[3]-\d[3]$/g, '').length === 13;

export const mapVoucherCreditCardFormToPaymentInfo = (
  values: VoucherCardForm,
): VoucherPaymentInfo => ({
  cardNumber: formatCreditCardNumberForServer(values.cardNumber),
  cardExpirationDate: formatCardExpireDateForServer(values.cardExpirationDate),
  phoneNumber: values.phoneNumber,
  email: values.email,
  issueComment: values.issueComment,
});

export const getVoucherCardInitialValues = (
  data?: VoucherInterface | null,
): VoucherCardForm => {
  if (!data) {
    return voucherCardFormInitialValues;
  }
  return {
    phoneNumber:
      data.paymentInfo?.phoneNumber || voucherCardFormInitialValues.phoneNumber,
    email: data.paymentInfo?.email || voucherCardFormInitialValues.email,
    cardExpirationDate: formatDateToExpireDate(
      data.paymentInfo?.cardExpirationDate,
    ),
    cardNumber:
      data.paymentInfo?.cardNumber || voucherCardFormInitialValues.cardNumber,
    voucherIssues: mapVoucherFormVoucherIssuesToForm(data.voucherIssues),
    issueComment:
      data.paymentInfo?.issueComment ||
      voucherCardFormInitialValues.issueComment,
    isStamped:
      Boolean(data.customsMark) || voucherCardFormInitialValues.isStamped,
    customsCode:
      data.customsMark?.customsCode || voucherCardFormInitialValues.customsCode,
    stampNumber:
      data.customsMark?.stampNumber || voucherCardFormInitialValues.stampNumber,
    markedAt:
      data.customsMark?.markedAt || voucherCardFormInitialValues.markedAt,
  };
};

export const isVoucherProcessingEditable = (
  voucher: VoucherInterface,
): boolean =>
  voucher.status && !registriesNotEditableStatuses.includes(voucher.status);

export const mapVoucherFormVoucherIssuesToParams = (
  prevVoucherIssues: VoucherIssuesType,
  voucherIssues: string[],
): Partial<VoucherIssuesType> => {
  const res: Partial<VoucherIssuesType> = {};
  Object.entries(prevVoucherIssues).forEach(([key, value]) => {
    if (value === null || key === 'id') {
      res[key] = value;
      return;
    }
    if (typeof value !== 'boolean') return;
    if (voucherIssues.includes(key)) {
      res[key] = true;
    } else {
      res[key] = false;
    }
  });
  return res;
};

export const mapVoucherFormVoucherIssuesToForm = (
  voucherIssues?: VoucherIssuesType,
): string[] => {
  if (!voucherIssues) return [];
  return Object.entries(voucherIssues).reduce((acc, [key, value]) => {
    if (!value || typeof value !== 'boolean') return acc;
    acc.push(key);
    return acc;
  }, [] as string[]);
};

export const checkIsVoucherHaveRegistry = (
  voucher: VoucherInterface,
): boolean | undefined =>
  !isEmpty(voucher.voucherRegistries) &&
  voucher.voucherRegistries?.some((item) => !item.deletedAt);
