import { FC } from 'react';

export interface ILogoProps {
  width?: number;
  height?: number;
}

const FixLogo: FC<ILogoProps> = ({ width = 183, height = 26 }) => (
  <img src="/img/logo.svg" alt="Link Logo" style={{ width, height }} />
);

export default FixLogo;
