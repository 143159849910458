import store from '@store/index';
import throttle from 'lodash/throttle';
import { useEffect } from 'react';

export default function useWindowWidth() {
  useEffect(() => {
    const listener = throttle(() => {
      store.ui.setWindowWidth(window.innerWidth);
    }, 300);
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);
}
