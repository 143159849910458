// глобальные стили
import './assets/styles/globals.scss';
import './assets/styles/icons.scss';
import './assets/styles/layout.scss';

import { setEntrancePoint } from '@services/utils/storage';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import initGrid from './services/utils/grid';

moment.locale('ru');

initGrid();

// Запоминаем точку входа в приложение
setEntrancePoint();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
