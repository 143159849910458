/* eslint-disable import/no-cycle */
import { FiscalAccumulatorFilter } from '@components/fiscalAccumulator/types';
import { IPaymentHistoryFilter } from '@store/entities/booker';
import { BrandsFilter } from '@store/entities/brands';
import { CashiersIssuerFilter } from '@store/entities/cashiersIssuer';
import { CashiersRefundFilter } from '@store/entities/cashiersRefund';
import { CityFilter } from '@store/entities/cities';
import { ICompaniesFilters } from '@store/entities/companies';
import { CompanyGroupFilter } from '@store/entities/companyGroups';
import { CustomsOfficesFilter } from '@store/entities/customs-offices';
import { IIntegrationsFilter } from '@store/entities/integrations';
import { IssuerFilter } from '@store/entities/issuers';
import { LogisticRegistriesFilter } from '@store/entities/logisticRegistries';
import { MailboxesFilter } from '@store/entities/mailboxes';
import { PaymentOrdersFilter } from '@store/entities/paymentOrders';
import { RefundFilter } from '@store/entities/refund';
import { RefundOfficesFilter } from '@store/entities/refund-offices';
import {
  RegistryFilter,
  RegistrySendFilter,
  RegistryToPayFilter,
} from '@store/entities/registry';
import { ShopsFilter } from '@store/entities/shops';
import { UsersFilter } from '@store/entities/users';
import { VouchersFilter } from '@store/entities/voucher';

import { VouchersCashierPaidFilter } from '../containers/roles/cashierRefund/VouchersPaid/types';

export const initialPaymentOrdersFilter: PaymentOrdersFilter = {
  createdAt: null,
  id: '',
  status: null,
  bankStatus: null,
  voucherId: '',
  company: null,
};

export const initialVouchersPaidFilter: VouchersCashierPaidFilter = {
  number: '',
  createdAt: null,
  updatedAt: null,
  refundOffice: null,
  status: null,
};

export const initialRegistryFilter: RegistryFilter = {
  number: '',
  createdAt: null,
  status: null,
  type: null,
  refundOffice: null,
};

export const initialRegistryToPayFilter: RegistryToPayFilter = {
  number: '',
  createdAt: null,
  status: null,
};

export const initialRegistrySendFilter: RegistrySendFilter = {
  number: '',
  createdAt: null,
  status: null,
};

export const initialLogisticRegistriesFilter: LogisticRegistriesFilter = {
  id: '',
  number: '',
  createdAt: null,
  checkpoint: null,
  status: null,
};

export const initialPaymentHistoryFilter: IPaymentHistoryFilter = {
  createdAt: null,
  amount: '',
};

export const initialFiscalAccumulators: FiscalAccumulatorFilter = {
  id: '',
  company: null,
  number: '',
  createdAt: null,
  status: null,
};

export const initialUsersFilter: UsersFilter = {
  id: '',
  name: '',
  createdAt: null,
  status: null,
  role: '',
  companyGroup: null,
  username: '',
  issuers: '',
};

export const initialCompanyGroupsFilter: CompanyGroupFilter = {
  id: '',
  name: '',
  createdAt: null,
  status: null,
  type: null,
};

export const initialCompanyFilter: ICompaniesFilters = {
  id: '',
  name: '',
  companyGroup: null,
  status: null,
  createdAt: null,
  minpromtorgRegistrationDate: null,
};

export const initialRefundsFilter: RefundFilter = {
  id: '',
  name: '',
  company: null,
  address: '',
  status: null,
  createdAt: null,
};

export const initialIssuersFilter: IssuerFilter = {
  id: '',
  name: '',
  company: null,
  status: '',
  address: '',
  createdAt: null,
};

export const initialVouchersFilter: VouchersFilter = {
  hrIdentifier: '',
  company: null,
  status: null,
  statusArray: [],
  createdAt: null,
  paidAt: null,
  fiscalTotalAmount: null,
  fiscalAccumulatorNumber: null,
  fiscalIssuedAt: null,
  refundOffice: null,
  issuer: null,
};

export const initialCashiersRefundFilter: CashiersRefundFilter = {
  id: '',
  name: '',
  createdAt: null,
  status: null,
};

export const initialCashiersIssuerFilter: CashiersIssuerFilter = {
  id: '',
  name: '',
  username: '',
  issuers: '',
  createdAt: null,
  status: null,
};

export const initialBrandsFilter: BrandsFilter = {
  id: '',
  createdAt: null,
  name: '',
  isVisible: null,
  status: null,
};

export const initialShopsFilter: ShopsFilter = {
  city: null,
  id: '',
  createdAt: null,
  name: '',
  status: null,
};

export const initialCitiesFilter: CityFilter = {
  id: '',
  shopsExists: false,
  refundOfficesExists: false,
  mailboxesExists: false,
  customOfficesExists: false,
  name: '',
};

export const initialCustomsOfficesFilter: CustomsOfficesFilter = {
  city: null,
  status: null,
  id: '',
  name: '',
  createdAt: null,
};

export const initialRefundOfficesFilter: RefundOfficesFilter = {
  city: null,
  status: null,
  id: '',
  name: '',
  createdAt: null,
};

export const initialMailboxesFilter: MailboxesFilter = {
  city: null,
  status: null,
  id: '',
  name: '',
  createdAt: null,
};

export const initialIntegrationsFilter: IIntegrationsFilter = {
  id: '',
  username: '',
  role: null,
  login: '',
};
