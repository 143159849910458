/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import FormControlLayout, {
  FormControlLayoutProps,
} from '@common/FormControlLayout';
import { isEmpty } from '@services/utils/validation';
import { FastField, Field, FieldProps } from 'formik';
import { ComponentType, FC, useCallback, useRef } from 'react';

import { errorHints } from '../../../consts/validation';

interface MakeFormFieldProps<OnChange, OnBlur>
  extends Omit<FormControlLayoutProps, 'errorText'> {
  name: string;
  onChange?: OnChange;
  onBlur?: OnBlur;
  disabled?: boolean;
  required?: boolean;
  errorText?: string; // Ошибка, переданная снаружи
  notErrorMessage?: boolean;
  useFastField?: boolean;
}

function withFormField<Props, OnChange, Value, OnBlur = (value: any) => void>(
  Component: ComponentType<Props>,
) {
  const FormField: FC<
    MakeFormFieldProps<OnChange, OnBlur> & Omit<Props, 'value' | 'onChange'>
  > = (props) => {
    const {
      name,
      disabled,
      layoutContainerClassName,
      title,
      withMargin,
      addEmptyTitle,
      onChange: onChangeProp,
      onBlur: onBlurProp,
      required,
      errorText,
      notErrorMessage,
      useFastField,
      tooltip,
      titleXs,
      titleSm,
      titleMd,
      titleLg,
      titleXl,
      controlXs,
      controlSm,
      controlMd,
      controlLg,
      controlXl,
      titleOffset,
      controlOffset,
      controlOffsetRight,
      helperText,
      titleAlignSelf,
      ...rest
    } = props;

    const onChangeRef = useRef<any>();
    const onBlurRef = useRef<any>();

    const validate = useCallback(
      (value: any) => {
        if (required && isEmpty(value)) {
          return errorHints.required;
        }
        return null;
      },
      [required],
    );

    const Wrapper = useFastField ? FastField : Field;

    return (
      <Wrapper name={name} validate={validate}>
        {(fieldProps: FieldProps<Value>) => {
          const { field, form, meta } = fieldProps;
          if (!onChangeRef.current) {
            onChangeRef.current = (value: Value) => {
              form.setFieldValue(field.name, value);
            };
          }

          if (!onBlurRef.current) {
            onBlurRef.current = (value: Value) => {
              (onBlurProp as any)?.(value);
              // На данный момент не нужна валидация при blur
              // form.setFieldTouched(field.name, true);
            };
          }

          const calculatedErrorText = (() => {
            if (errorText) {
              return errorText;
            }
            return meta.touched ? meta.error : '';
          })();

          return (
            <FormControlLayout
              titleAlignSelf={titleAlignSelf}
              helperText={helperText}
              titleOffset={titleOffset}
              titleXs={titleXs}
              titleSm={titleSm}
              titleMd={titleMd}
              titleLg={titleLg}
              titleXl={titleXl}
              controlOffset={controlOffset}
              controlOffsetRight={controlOffsetRight}
              controlXs={controlXs}
              controlSm={controlSm}
              controlMd={controlMd}
              controlLg={controlLg}
              controlXl={controlXl}
              addEmptyTitle={addEmptyTitle}
              title={title}
              errorText={calculatedErrorText}
              notErrorMessage={notErrorMessage}
              layoutContainerClassName={layoutContainerClassName}
              withMargin={withMargin}
              tooltip={tooltip}
            >
              <Component
                onBlur={onBlurRef.current}
                name={name}
                disabled={form.isSubmitting || disabled}
                error={!!calculatedErrorText}
                value={field.value}
                onChange={onChangeProp || (onChangeRef.current as OnChange)}
                {...(rest as any)}
              />
            </FormControlLayout>
          );
        }}
      </Wrapper>
    );
  };
  return FormField;
}

export default withFormField;
