/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import {
  Option,
  OptionExtended,
  ValueNumber,
} from '../../store/entities/common';
import { formatNotNumberToNumber } from './numbers';

interface Settings {
  valueKey: string;
  labelKey?: string;
  getOptionLabel?: (option: any) => string;
  combineOptions?: boolean;
}

type GetOptionFromOptionsSettings = Settings;

export const getOptionFromOptions = (
  value: string | number | boolean | null | undefined,
  options: OptionExtended[] | undefined,
  { valueKey, labelKey, getOptionLabel }: GetOptionFromOptionsSettings,
): Option => {
  const found = options?.find((option) => option?.[valueKey] === value);
  const rawValue = found?.[valueKey];

  return {
    id: String(found?.id ?? Math.random()),
    value: rawValue === false ? false : (rawValue ?? ''),
    label: getLabel(found, { labelKey, getOptionLabel }),
  };
};

type getOptionsFromAnySettings = Settings;

export const getOptionsFromAny = (
  options: any[] | null | undefined,
  {
    valueKey,
    labelKey,
    getOptionLabel,
    combineOptions,
  }: getOptionsFromAnySettings,
): OptionExtended<{ combined?: Option[] }>[] => {
  if (!options?.length) return [];

  const mapped = options.map((item) => {
    const value = item[valueKey];
    return {
      id: item.id ?? String(value),
      value,
      label: getLabel(item, { labelKey, getOptionLabel }),
    };
  });

  if (!combineOptions) return mapped;

  const combinedMap = new Map<string, OptionExtended<{ combined: Option[] }>>();

  for (const option of mapped) {
    const existing = combinedMap.get(option.label);
    if (existing) {
      existing.combined.push(option);
    } else {
      combinedMap.set(option.label, { ...option, combined: [option] });
    }
  }

  return Array.from(combinedMap.values());
};

export const getLabel = (
  option: any,
  settings: Omit<Settings, 'valueKey'>,
): string => {
  const { labelKey, getOptionLabel } = settings;
  if (!option) {
    return '';
  }
  if (getOptionLabel) {
    return getOptionLabel(option) || '';
  }
  if (labelKey) {
    return option[labelKey] || '';
  }
  return '';
};

interface FullNameObject {
  firstName?: string | null;
  lastName?: string | null;
  fatherName?: string | null;
}

export const getFullName = (obj?: FullNameObject): string => {
  if (!obj) {
    return '';
  }
  const formatItem = (item?: string | null) => item || '';
  return [obj.lastName, obj.firstName, obj.fatherName].reduce(
    (acc: string, cur) => {
      const formattedItem = formatItem(cur);
      if (!formattedItem) {
        return acc;
      }
      if (!acc) {
        return formattedItem;
      }
      return `${acc} ${formattedItem}`;
    },
    '',
  );
};

export const getFullFiscalStr = (array: any[]): string => {
  if (!array) {
    return '';
  }
  return array.reduce((acc, fiscal) => `${acc} ${fiscal.number}`, '');
};

export const getStringWithFilteredSpace = (str?: string): string => {
  if (!str) {
    return '';
  }
  return str.trim().replace(/\s+/g, ' ');
};

export const getNumberDeclensions = (
  value: ValueNumber,
  forms?: string[],
  defaultValue = '',
): string => {
  let numberValue = formatNotNumberToNumber(value);
  if (numberValue === null) {
    return '';
  }
  numberValue = Math.abs(numberValue) % 100;
  const n1 = numberValue % 10;
  if (numberValue > 10 && numberValue < 20) {
    return forms?.[2] || defaultValue || '';
  }
  if (n1 > 1 && n1 < 5) {
    return forms?.[1] || defaultValue || '';
  }
  if (n1 === 1) {
    return forms?.[0] || defaultValue || '';
  }
  return forms?.[2] || defaultValue || '';
};

export const hasSelection = (): boolean => !!window.getSelection()?.toString();

export const convertObjectToFormData = (obj: any): FormData => {
  const formData = new FormData();
  if (!obj) {
    return formData;
  }
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key]);
  });
  return formData;
};

export const isInn = (value: string) =>
  /^\d+$/.test(value) && value.length < 13;
