import ButtonIcon from '@common/ButtonIcon';
import { HintSrvIcon16 } from '@sberbusiness/icons/HintSrvIcon16';
import {
  ETooltipPreferPlace,
  ETooltipSize,
} from '@sberbusiness/triplex/components/Tooltip/enums';
import {
  ITooltipProps,
  Tooltip,
} from '@sberbusiness/triplex/components/Tooltip/Tooltip';
import classnames from 'classnames';
import { FC, JSX, ReactNode, useEffect, useRef } from 'react';

import styles from './styles.module.scss';

interface Props extends Pick<ITooltipProps, 'toggleType' | 'preferPlace'> {
  tooltip: ReactNode;
  label?: ReactNode;
  containerClassName?: string;
  labelClassName?: string;
  customTarget?: JSX.Element;
  align?: 'left' | 'center' | 'right';
  underlineOnHover?: boolean;
  size?: ETooltipSize;
}

const SbBolTooltip: FC<Props> = (props) => {
  const {
    tooltip,
    label,
    toggleType = 'hover',
    preferPlace = ETooltipPreferPlace.ABOVE,
    containerClassName: containerClassNameProp,
    labelClassName,
    customTarget,
    align = 'left',
    underlineOnHover,
    size = ETooltipSize.SM,
  } = props;
  const ref = useRef<HTMLButtonElement | null>(null);

  let target = customTarget;
  if (!target) {
    target = (
      <ButtonIcon ref={ref}>
        <HintSrvIcon16 />
      </ButtonIcon>
    );
  }
  const tooltipComponent = (
    <Tooltip size={size} toggleType={toggleType} preferPlace={preferPlace}>
      <Tooltip.Body>{tooltip}</Tooltip.Body>
      <Tooltip.Target>{target}</Tooltip.Target>
    </Tooltip>
  );

  const containerClassName = classnames(
    styles.container,
    {
      [styles.containerUnderline]: underlineOnHover,
      [styles.containerLeft]: align === 'left',
      [styles.containerCenter]: align === 'center',
      [styles.containerRight]: align === 'right',
    },
    containerClassNameProp,
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute('tabindex', '-1');
    }
  }, []);

  if (label) {
    return (
      <div className={containerClassName}>
        <div className={classnames(styles.label, labelClassName)}>{label}</div>
        {tooltipComponent}
      </div>
    );
  }
  return <div className={containerClassName}>{tooltipComponent}</div>;
};

export default SbBolTooltip;
