/* eslint-disable react/display-name */
import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';

import styles from './styles.module.scss';

const ButtonIcon = forwardRef<
  HTMLButtonElement,
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>((props, ref) => {
  const { className, children, type = 'button', ...rest } = props;
  return (
    <button
      {...rest}
      ref={ref}
      className={classNames(styles.button, className)}
      type={
        type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'
      }
    >
      {children}
    </button>
  );
});

export default ButtonIcon;
