/* eslint-disable import/no-cycle */
import config from '@consts/config';
import { getCredentials, removeCredentials } from '@services/utils/storage';
import axiosInstance, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';

const urlsWithNoAuth = new Set([
  '/client_subscription_requests',
  '/authentication_token',
  '/log',
]);

const axios = axiosInstance.create({
  baseURL: config.host + config.prefix,
  headers: {
    'Accept-Language': 'ru',
  },
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: 'brackets', allowDots: true }),
});

export const axiosDadata = axiosInstance.create({
  baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
  headers: {
    authorization: `Token ${config.dadataApiKey}`,
  },
});

const isAuthRequest = (propsConfig: AxiosRequestConfig | undefined) => {
  if (!propsConfig) return false;
  if (propsConfig.url) {
    return urlsWithNoAuth.has(propsConfig.url);
  }
  return false;
};

axios.interceptors.request.use((propsConfig) => {
  const newConfig = { ...propsConfig };
  if (!isAuthRequest(propsConfig)) {
    const token = getCredentials();
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
});

let saveFrontVersionServer = '';

axios.interceptors.response.use(
  (response) => {
    // Если запрос успешен, нам не нужно ничего делать, а просто вернуть ответ.
    const { headers } = response;
    const frontVersionServer = headers['x-front-version'];
    if (frontVersionServer) {
      if (
        saveFrontVersionServer &&
        frontVersionServer !== saveFrontVersionServer
      ) {
        window.location.reload();
      } else {
        saveFrontVersionServer = frontVersionServer;
      }
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401 && !isAuthRequest(error.config)) {
      removeCredentials();
      window.location.reload();
    }
    // Если ошибка вызвана другими причинами, мы просто отбрасываем ее обратно в axios.
    return Promise.reject(error);
  },
);

export default axios;
