import Button, { ButtonType } from '@common/Button';
import { InputFormField } from '@common/Input';
import { InputPasswordFormField } from '@common/InputPassword';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import styles from './styles.module.scss';

const LoginForm: FC = () => {
  const { isSubmitting } = useFormikContext();
  return (
    <>
      <div className={styles.inputs}>
        <InputFormField
          name="login"
          placeholder="Логин"
          className={styles.input}
        />
        <InputPasswordFormField
          name="password"
          placeholder="Пароль"
          className={styles.input}
        />
      </div>
      <Button
        fullWidth
        size="lg"
        variant="general"
        type={ButtonType.SUBMIT}
        name="login"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Войти
      </Button>
    </>
  );
};

export default LoginForm;
