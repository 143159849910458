import { HOME_PATH } from '@consts/app';
import store from '@store/index';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const LoginRoute: FC<RouteProps> = ({ children }) =>
  store.currentUser.hasToken ? (
    <Redirect to={HOME_PATH} />
  ) : (
    <Route>{children}</Route>
  );

export default observer(LoginRoute);
