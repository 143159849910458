/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, FC } from 'react';
import { Row, RowProps } from 'react-grid-system';

const defaultSpacing = 16;

export const GridContext = createContext({
  gutterWidth: defaultSpacing,
});

const GridContainer: FC<RowProps> = ({
  gutterWidth = defaultSpacing,
  children,
  align = 'center',

  ...rest
}) => (
  <GridContext.Provider
    value={{
      gutterWidth,
    }}
  >
    <Row
      gutterWidth={gutterWidth}
      align={align}
      {...(rest as any)}
      style={{ margin: -gutterWidth / 2 }}
    >
      {children}
    </Row>
  </GridContext.Provider>
);

export default GridContainer;
