/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx';

import { HttpClient } from '../services/api/http-client';
import { Registry } from './entities/registry';
import type { RootStore } from './index';
import { NOTIFICATION_TYPE } from './notification-store';

export default class RegistryReceivedStore {
  items: Registry[] = [];

  deletingIds: string[] = [];

  loading = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  // eslint-disable-next-line consistent-return
  addRegistryToList = async (registryId: string): Promise<void> => {
    this.loading = true;
    try {
      await HttpClient.getAxios<Registry>(`/voucher_registries`);
      const { data } = await HttpClient.getAxios<Registry>(
        `/voucher_registries/${registryId}`,
      );
      if (
        !['to_sending_to_main_office', 'to_sending_to_operator'].includes(
          data.status,
        )
      ) {
        this.rootStore.notification.open(
          `Реестр ${data.id} находится в статусе "${this.rootStore.genericEntities.convertToRus(
            data.status,
            'registryStatuses',
          )}"`,
          NOTIFICATION_TYPE.warning,
          [],
        );
        return Promise.reject();
      }
      if (this.items.find((registry) => registry.id === data.id)) {
        this.rootStore.notification.open(
          'Реестр уже добавлен в список',
          NOTIFICATION_TYPE.warning,
          [],
        );
        return Promise.reject();
      }
      if (data.status === 'to_sending_to_operator') {
        this.items = [...this.items, data];
        this.rootStore.notification.open(
          `Реестр ${data.id} уже находится в статусе "${this.rootStore.genericEntities.convertToRus(
            data.status,
            'registryStatuses',
          )}"`,
          NOTIFICATION_TYPE.warning,
          [],
        );
        return Promise.reject();
      }
      const registryNewResponse = await HttpClient.patchAxios<Registry>(
        `/voucher_registries/${registryId}`,
        {
          status: 'to_sending_to_operator',
        },
      );
      this.items = [...this.items, registryNewResponse.data];
    } catch (e: any) {
      HttpClient.log(e, {
        component: 'RegistryReceivedStore',
        functionName: 'addRegistryToList',
        codeLine: 65,
        requestUrl: `/voucher_registries/${registryId}`,
        comments: 'GET',
      });

      HttpClient.log(e, {
        component: 'RegistryReceivedStore',
        functionName: 'addRegistryToList',
        codeLine: 73,
        requestUrl: `/voucher_registries/${registryId}`,
        request: {
          status: 'to_sending_to_operator',
        },
        comments: 'PATCH',
      });

      if (e?.response?.status === 404) {
        this.rootStore.notification.open(
          'Такого реестра не существует в системе',
          NOTIFICATION_TYPE.error,
          [],
        );
      } else {
        this.rootStore.notification.handleResponseError(e);
      }
      return Promise.reject(e);
    } finally {
      this.loading = false;
    }
  };

  deleteRegistryFromList = async (registryId: string): Promise<void> => {
    this.deletingIds = [...this.deletingIds, registryId];
    try {
      const { data } = await HttpClient.patchAxios<Registry>(
        `/voucher_registries/${registryId}`,
        {
          status: 'to_sending_to_main_office',
        },
      );
      this.rootStore.notification.open(
        `Реестр ${registryId} успешно возвращен в статус "${this.rootStore.genericEntities.convertToRus(
          data.status,
          'registryStatuses',
        )}"`,
        NOTIFICATION_TYPE.success,
        [],
      );
    } catch (e: any) {
      this.rootStore.notification.handleResponseError(e);
      HttpClient.log(e, {
        component: 'registry-received',
        functionName: 'deleteRegistryFromList',
        codeLine: 115,
        requestUrl: `/voucher_registries/${registryId}`,
        request: {
          status: 'to_sending_to_main_office',
        },
        method: 'PATCH',
      });
    } finally {
      this.items = this.items.filter((registry) => registry.id !== registryId);
      this.deletingIds = this.deletingIds.filter((item) => item !== registryId);
    }
  };
}
