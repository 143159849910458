import withFormField from '../withFormField';
import SegmentedControlSingle, { Props } from './SegmentedControlSingle';

export type SegmentedControlSingleProps = Props;
export const SegmentedControlSingleFormField = withFormField<
  SegmentedControlSingleProps,
  SegmentedControlSingleProps['onChange'],
  SegmentedControlSingleProps['value']
>(SegmentedControlSingle);
export default SegmentedControlSingle;
