import withFormField from '../withFormField';
import InputPassword, { Props } from './InputPassword';

export type InputPasswordProps = Props;
export const InputPasswordFormField = withFormField<
  InputPasswordProps,
  InputPasswordProps['onChange'],
  InputPasswordProps['value']
>(InputPassword);
export default InputPassword;
