export enum IsCheckedScanner {
  Default = 'Default',
  Success = 'Success',
  Failed = 'Failed',
  Fixed = 'Fixed',
}

export const textIsCheckedScannerAlerts = {
  [IsCheckedScanner.Failed]:
    'При сканировании чека обнаружена ошибка. Для оформления Tax Free заполните данные чека вручную. Мы сообщим Вам об устранении ошибки.',
  [IsCheckedScanner.Success]:
    'Отсканируйте чек или заполните данные чека вручную.',
  [IsCheckedScanner.Default]:
    'Ваш сканер не проверен, может возникать ошибка при сканировании чека. При возникновении ошибки сканирования, заполните данные чека вручную. Для проверки сканера нажмите справа значок «Настройки».',
};

export const textIsCheckedScannerModal = {
  [IsCheckedScanner.Failed]: [
    'При сканировании чека обнаружена ошибка',
    'Мы уже работаем над устранением ошибки. Вы можете работать в системе и оформлять Tax Free для этого заполните данные чека вручную.',
  ],
  [IsCheckedScanner.Success]: [
    'Сканирование чека прошло успешно',
    'Желаем приятной работы!',
  ],
  [IsCheckedScanner.Default]: 'Для проверки сканера отсканируйте QR-код.',
  [IsCheckedScanner.Fixed]:
    'У нас хорошая новость! Исправлены ранее выявленные ошибки сканера. Повторно проверьте настройки сканера, для этого отсканируйте QR-код.',
};

export interface ICheckingScanError {
  testScanFail: boolean;
  date: string | null;
  issuerId: string;
  issuerName: string;
  userId: string;
  userLogin: string;
  value: string;
}
