/* eslint-disable import/no-cycle */
import { DateRangePickerValue } from '@common/DateRangePicker';
import moment, { Moment } from 'moment';

export const formatDateToValue = (
  date?: string | Moment | Date | null,
  format?: string,
): string | null => {
  if (!date) {
    return null;
  }
  if (moment(Date.parse(`${date}`)).isValid()) {
    return moment(Date.parse(`${date}`))
      .local()
      .format(format);
  }
  return null;
};

export const getDateStart = (
  date?: string | Moment | Date | null,
): string | null => {
  if (!date) {
    return null;
  }
  return formatDateToValue(
    moment(date).hours(0).minutes(0).seconds(0).milliseconds(0),
  );
};

export const getDateEnd = (
  date?: string | Moment | Date | null,
): string | null => {
  if (!date) {
    return null;
  }
  return formatDateToValue(
    moment(date).hours(23).minutes(59).seconds(59).milliseconds(999),
  );
};

interface FormatDateOptions {
  format?: string;
  withHours?: boolean;
}

export const formatDate = (
  date?: string | Moment | Date | null,
  options: FormatDateOptions = {},
): string => {
  const { withHours } = options;
  let { format } = options;
  if (!date) {
    return '';
  }
  if (!format) {
    format = 'DD.MM.YYYY';
    if (withHours) {
      format = `${format},\u00A0HH:mm`;
    }
  }
  if (moment(Date.parse(`${date}`)).isValid()) {
    return moment(date).local().format(format);
  }
  return '';
};

export const getServerPeriod = (
  period: DateRangePickerValue | null,
  key = 'createdAt',
): Record<string, string> => {
  const serverPeriod: Record<string, string> = {};
  const startFormatted = formatDateToValue(period?.start);
  const endFormatted = formatDateToValue(period?.end);
  if (endFormatted) {
    serverPeriod[`${key}[before]`] = endFormatted;
  }
  if (startFormatted) {
    serverPeriod[`${key}[after]`] = startFormatted;
  }
  return serverPeriod;
};

export const formatSeconds = (sec: number): string => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  let secondsString = String(seconds);
  if (seconds < 10) {
    secondsString = `0${secondsString}`;
  }
  let minutesString = String(minutes);
  if (hours > 0) {
    minutesString = `0${minutesString}`;
  }
  if (hours > 0) {
    return `${hours}:${minutesString}:${secondsString}`;
  }
  return `${minutesString}:${secondsString}`;
};
