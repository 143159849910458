/* eslint-disable import/no-cycle */
import { ValueNumber } from '@store/entities/common';
import { IGenericVat, IVatFraction } from '@store/entities/generic';

export const formatNotNumberToNumber = (value: ValueNumber): number | null => {
  if (typeof value === 'number') {
    return value;
  }
  if (value === null || value === undefined || value === '') {
    return null;
  }
  const result = Number(value);
  return isNaN(result) ? null : result;
};

interface FormatNumberOptions extends Intl.NumberFormatOptions {
  divideByHundred?: boolean;
  tail?: string;
}

export const formatNumber = (
  value: ValueNumber,
  options: FormatNumberOptions = {},
): string => {
  const { divideByHundred, tail, maximumFractionDigits = 2, ...rest } = options;
  let numberValue = formatNotNumberToNumber(value);
  if (numberValue === null) {
    return '';
  }
  if (divideByHundred) {
    numberValue /= 100;
  }
  let formattedValue = numberValue.toLocaleString('ru', {
    maximumFractionDigits,
    ...rest,
  });
  if (tail) {
    formattedValue += `\u00A0${tail}`;
  }
  return formattedValue;
};

export const formatMoney = (
  value: ValueNumber,
  options: FormatNumberOptions = {},
): string =>
  formatNumber(value, { minimumFractionDigits: 2, tail: '₽', ...options });

export const calculateVatFraction = (
  pricePerItem: number,
  { vat, fee }: IGenericVat,
): IVatFraction => ({
  refundSum: (pricePerItem * fee) / (100 + vat),
  nds: (pricePerItem * vat) / (100 + vat),
});

export const calculateVatForTable = (
  totalPricePlus: number,
  { vat }: IGenericVat,
): string | null =>
  formatMoney((totalPricePlus * vat) / (100 + vat), {
    divideByHundred: true,
    tail: '',
  });

export const formatRubToPenny = (value: ValueNumber): string => {
  // вернуть нужно строку с округлением
  const numberValue = formatNotNumberToNumber(value);
  if (numberValue === null) return '';
  return String(Math.round(100 * numberValue));
};

export const formatPennyToRub = (value: ValueNumber): number | null => {
  // вернуть нужно число без округления
  const numberValue = formatNotNumberToNumber(value);
  if (numberValue === null) return null;
  return numberValue / 100;
};

export const roundRub = (value: ValueNumber): number | null =>
  formatPennyToRub(formatRubToPenny(value));

export const roundPenny = (value: ValueNumber): number | null => {
  const numberValue = formatNotNumberToNumber(value);
  if (numberValue === null) return null;
  return Math.round(numberValue);
};

export const getPartialString = (
  current: ValueNumber,
  max: ValueNumber,
): string => {
  const formattedCurrent = formatNumber(current, { maximumFractionDigits: 3 });
  const formattedMax = formatNumber(max, { maximumFractionDigits: 3 });
  if (!formattedCurrent) {
    return '';
  }
  if (!formattedMax || formattedCurrent === formattedMax) {
    return formattedCurrent;
  }
  return `${formattedCurrent}/${formattedMax}`;
};

export const createNumberMask = (digits: number): RegExp[] =>
  new Array(digits).fill(/\d/);
