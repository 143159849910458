/* eslint-disable import/no-cycle */
import GridItem from '@common/GridItem';
import Tooltip from '@common/Tooltip';
import { BREAKPOINTS, indexMap } from '@constApp';
import { ReactComponent as ErrorIcon } from '@icons/error-icon.svg';
import store from '@store/index';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, ReactNode } from 'react';
import { Offsets } from 'react-grid-system';

import styles from './styles.module.scss';

export interface Props {
  layoutContainerClassName?: string;
  title?: string;
  errorText?: string;
  withMargin?: boolean;
  addEmptyTitle?: boolean;
  notErrorMessage?: boolean;
  tooltip?: string;
  titleClassName?: string;
  titleXs?: number;
  titleSm?: number;
  titleMd?: number;
  titleLg?: number;
  titleXl?: number;
  controlXs?: number;
  controlSm?: number;
  controlMd?: number;
  controlLg?: number;
  controlXl?: number;
  titleOffset?: Offsets;
  titleOffsetRight?: Offsets;
  controlOffset?: Offsets;
  controlOffsetRight?: Offsets;
  helperText?: ReactNode | ReactNode[];
  titleAlignSelf?: CSSProperties['alignSelf'];
  controlClassName?: string;
}

const FormControlLayout: FC<Props> = observer((props) => {
  const {
    withMargin,
    layoutContainerClassName,
    titleClassName: titleClassNameProp,
    title,
    children,
    errorText,
    addEmptyTitle,
    notErrorMessage,
    tooltip,
    titleSm,
    titleMd,
    titleLg,
    titleXl,
    controlSm,
    controlMd,
    controlLg,
    controlXl,
    titleOffset,
    titleOffsetRight,
    controlOffset,
    controlOffsetRight,
    helperText,
    titleAlignSelf,
    controlClassName,
  } = props;

  const containerClassName = classNames(
    styles.container,
    layoutContainerClassName,
    {
      [styles.containerMarginBottom]: withMargin,
    },
  );

  const titleClassName = classNames(styles.title, titleClassNameProp);

  const errorComponent = errorText && !notErrorMessage && (
    <div className={styles.error}>
      <ErrorIcon />
      {errorText}
    </div>
  );

  const titleWithHelperText = helperText ? (
    <div>
      <div>{title}</div>
      {Array.isArray(helperText) ? (
        helperText.map((helperText, index) => (
          <div
            className={classNames(styles.helperText, {
              [styles.helperTextMargin]: title || index !== 0,
            })}
            key={indexMap[index]}
          >
            {helperText}
          </div>
        ))
      ) : (
        <div
          className={classNames(styles.helperText, {
            [styles.helperTextMargin]: title,
          })}
        >
          {helperText}
        </div>
      )}
    </div>
  ) : (
    title
  );

  const titleComponent = tooltip ? (
    <Tooltip
      label={titleWithHelperText}
      tooltip={tooltip}
      labelClassName={titleClassName}
    />
  ) : (
    <div className={titleClassName}>{titleWithHelperText}</div>
  );

  const formControlComponent = (
    <div className={containerClassName}>
      {titleComponent}
      {addEmptyTitle && store.ui.windowWidth >= BREAKPOINTS.SM && (
        <div className={titleClassName}>{'\u00A0'}</div>
      )}
      <div className={classNames(styles.control, controlClassName)}>
        {children}
      </div>
      {errorComponent}
    </div>
  );

  if (
    (titleSm && controlSm) ||
    (titleMd && controlMd) ||
    (titleLg && controlLg) ||
    (titleXl && controlXl)
  ) {
    if (store.ui.windowWidth >= BREAKPOINTS.SM) {
      return (
        <>
          <GridItem
            alignSelf={titleAlignSelf}
            sm={titleSm}
            md={titleMd}
            lg={titleLg}
            xl={titleXl}
            className={titleClassName}
            offset={titleOffset}
          >
            {titleComponent}
          </GridItem>
          {titleOffsetRight && <GridItem {...titleOffsetRight} />}
          <GridItem
            sm={controlSm}
            md={controlMd}
            lg={controlLg}
            xl={controlXl}
            offset={controlOffset}
          >
            <div className={containerClassName}>
              <div className={styles.control}>{children}</div>
              {errorComponent}
            </div>
          </GridItem>
          {controlOffsetRight && <GridItem {...controlOffsetRight} />}
        </>
      );
    }
    return <GridItem xs={12}>{formControlComponent}</GridItem>;
  }

  return formControlComponent;
});

export default FormControlLayout;
