/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  initialBrandsFilter,
  initialCashiersIssuerFilter,
  initialCashiersRefundFilter,
  initialCitiesFilter,
  initialCompanyFilter,
  initialCompanyGroupsFilter,
  initialCustomsOfficesFilter,
  initialFiscalAccumulators,
  initialIntegrationsFilter,
  initialIssuersFilter,
  initialLogisticRegistriesFilter,
  initialMailboxesFilter,
  initialPaymentHistoryFilter,
  initialPaymentOrdersFilter,
  initialRefundOfficesFilter,
  initialRefundsFilter,
  initialRegistryFilter,
  initialRegistrySendFilter,
  initialRegistryToPayFilter,
  initialShopsFilter,
  initialUsersFilter,
  initialVouchersFilter,
  initialVouchersPaidFilter,
} from '@consts/filters';
import isEqual from 'lodash/isEqual';
import { makeAutoObservable } from 'mobx';

import { IFilters } from './entities/table';
import type { RootStore } from './index';

export default class FilterTableStore {
  filters: IFilters = {
    paymentOrders: { ...initialPaymentOrdersFilter },
    companies: { ...initialCompanyFilter },
    vouchersCashierPaid: { ...initialVouchersPaidFilter },
    paymentHistory: { ...initialPaymentHistoryFilter }, // бухгалтер оператора
    registryToPay: { ...initialRegistryToPayFilter }, // к оплате
    registry: { ...initialRegistryFilter }, // для оператора
    registryNotPaid: { ...initialRegistryFilter },
    registryCashPaid: { ...initialRegistryFilter },
    registrySendPaidByCash: { ...initialRegistrySendFilter }, // оплаченных для админки возрата
    registrySendNotPaid: { ...initialRegistrySendFilter }, // неполаченных для админки возрата
    registryProcessingPaidByCash: { ...initialRegistryFilter }, // оплаченных для процессинга
    registryProcessingNotPaid: { ...initialRegistryFilter }, // неполаченных для процессинга
    logisticRegistries: { ...initialLogisticRegistriesFilter }, // сбер логистика
    fiscalAccumulators: { ...initialFiscalAccumulators },
    users: { ...initialUsersFilter },
    companyGroups: { ...initialCompanyGroupsFilter },
    refunds: { ...initialRefundsFilter },
    vouchers: { ...initialVouchersFilter },
    issuers: { ...initialIssuersFilter },
    cashiersRefund: { ...initialCashiersRefundFilter },
    cashierIssuer: { ...initialCashiersIssuerFilter },
    brands: { ...initialBrandsFilter },
    shops: { ...initialShopsFilter },
    customsOffices: { ...initialCustomsOfficesFilter },
    refundOffices: { ...initialRefundOfficesFilter },
    mailboxes: { ...initialMailboxesFilter },
    cities: { ...initialCitiesFilter },
    integrations: { ...initialIntegrationsFilter },
  };

  constructor(private rootState: RootStore) {
    makeAutoObservable(this);
  }

  getFilterValue = (key: keyof IFilters) => {
    return this.filters[key];
  };

  setFilterValue = (key: keyof IFilters, value: any) => {
    this.filters[key] = value;
  };

  isFilterValueChanged = (key: keyof IFilters, initialValue: any): boolean => {
    return !isEqual(this.filters[key], initialValue);
  };
}
