/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx';

import { HttpClient } from '../services/api/http-client';
import CancelableRequest from '../services/CancelableRequest';
import { formatDate } from '../services/utils/dates';
import { formatMoney } from '../services/utils/numbers';
import type { RootStore } from './index';
import { NOTIFICATION_TYPE, TITLE_ERROR } from './notification-store';

export default class SearchingStore {
  constructor(private rootState: RootStore) {
    makeAutoObservable(this);
  }

  total = ''; // TODO coming not corårect from server

  suggestions: any[] = [];

  loading = true;

  fetchData = CancelableRequest.create(
    async (source, url: string, params: any) => {
      if (!url) {
        this.rootState.notification.open(
          TITLE_ERROR.error,
          NOTIFICATION_TYPE.error,
          [''],
        );
        return Promise.reject(new Error('error'));
      }
      try {
        this.setLoading(true);
        const response = await HttpClient.getList(url, {
          cancelToken: source.token,
          params,
        });
        this.setLoading(false);
        this.setSuggest(response.data);
        return Promise.resolve(this.suggestions);
      } catch (e: any) {
        HttpClient.log(e, {
          component: 'SearchingStore',
          functionName: 'SearchingStore',
          codeLine: 38,
          requestUrl: url,
          request: {
            cancelToken: source.token,
            params,
          },
        });

        if (CancelableRequest.isCancelledByOuterCall(e)) {
          this.setLoading(false);
        }
        if (!CancelableRequest.isCancelledByNextRequest(e)) {
          this.rootState.notification.open(
            TITLE_ERROR.error,
            NOTIFICATION_TYPE.error,
            [''],
          );
          this.setLoading(false);
        }
      }

      return this.clearSuggestions();
    },
  );

  clearSuggestions = (): void => {
    this.suggestions = [];
  };

  setSuggest = (items: any) => {
    this.suggestions = this.optimizerDataSearchSuggestion(items);
  };

  setLoading = (flag: boolean): void => {
    this.loading = flag;
  };

  optimizerDataSearchSuggestion = (data: any) =>
    data.reduce(
      (acc: any, item: any) => [
        ...acc,
        {
          id: item.identifier,
          hrIdentifier: item.hrIdentifier,
          createdAt: formatDate(item.createdAt.date, { withHours: true }),
          totalAmount: formatMoney(item.totalAmount, { divideByHundred: true }),
        },
      ],
      [],
    );
}
