import Button from '@common/Button';
import Modal from '@common/Modal';
import rootStore from '@store/index';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';

const ConfirmConnected: FC = observer(() => {
  const handleClose = useCallback(() => {
    rootStore.ui.closeConfirm(false);
  }, []);

  const handleSubmit = useCallback(() => {
    rootStore.ui.closeConfirm(true);
  }, []);

  if (!rootStore.ui.confirm.isOpen) {
    // именно так, анимация закрытия мешает и лишний рендеринг
    return null;
  }

  return (
    <Modal
      isOpen
      header={rootStore.ui.confirm.title || ''}
      onClose={handleClose}
      colorHeader={rootStore.ui.confirm.colorModal}
      colorFooter={rootStore.ui.confirm.colorModal}
      buttons={
        <>
          <Button onClick={handleClose} variant="secondary">
            {rootStore.ui.confirm.cancelButtonText}
          </Button>
          <Button
            onClick={handleSubmit}
            variant={rootStore.ui.confirm.confirmButtonType}
          >
            {rootStore.ui.confirm.confirmButtonText}
          </Button>
        </>
      }
    >
      <div>{rootStore.ui.confirm.text}</div>
    </Modal>
  );
});

export default ConfirmConnected;
