// стили triplex
import '@sberbusiness/icons/css/icons.css';
import '@sberbusiness/triplex/styles/styles.css';

import AppNotification from '@common/Notification';
import ErrorBoundary from '@components/ErrorBoundary';
import { HOME_PATH } from '@consts/app';
import Login from '@containers/Login';
import Alert from '@containers/views/app/modals/Alert';
import Confirm from '@containers/views/app/modals/Confirm';
import LoginRoute from '@containers/views/router/login-route';
import ProtectedRoute from '@containers/views/router/protected-route';
import useWindowWidth from '@hooks/useWindowWidth';
import { LINK_PATH } from '@link/LData/lData';
import { Spinner } from '@sberbusiness/triplex/components/Spinner/Spinner';
import lazyWithRetry from '@services/utils/lazyWithRetry';
import { FC, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const CheckScanner = lazyWithRetry(
  () => import('@containers/roles/cashier/CheckScanner'),
);
const ChangePassword = lazyWithRetry(
  () => import('@containers/ChangePassword'),
);
const SwitcherRoles = lazyWithRetry(() => import('@containers/SwitcherRoles'));

const ClientSubscription = lazyWithRetry(
  () => import('./components/clientSubscription/ClientSubscription'),
);

const ClientProcess = lazyWithRetry(
  () => import('@components/clientProcess/ClientProcess'),
);

const LinkLogin = lazyWithRetry(() => import('@link/LinkLogin'));

const ManagerPage = lazyWithRetry(
  () => import('@containers/LINK/ManagerModal/ManagerModal'),
);

const App: FC = () => {
  useWindowWidth();
  return (
    <>
      <ErrorBoundary>
        <Alert />
        <Confirm />
        <AppNotification />
        <Switch>
          <Route path={LINK_PATH}>
            <Suspense fallback={<Spinner />}>
              <LinkLogin />
            </Suspense>
          </Route>
          <Route path="/km">
            <Suspense fallback={<Spinner />}>
              <ManagerPage />
            </Suspense>
          </Route>
          <Route path="/client-subscription">
            <Suspense fallback={<Spinner />}>
              <ClientSubscription />
            </Suspense>
          </Route>
          <Route path="/client-process">
            <Suspense fallback={<Spinner />}>
              <ClientProcess />
            </Suspense>
          </Route>
          <Route path="/change-password">
            <Suspense fallback={<Spinner />}>
              <ChangePassword />
            </Suspense>
          </Route>
          <Route path="/check_scanner">
            <Suspense fallback={<Spinner />}>
              <CheckScanner />
            </Suspense>
          </Route>
          <LoginRoute exact path="/login">
            <Login />
          </LoginRoute>
          <ProtectedRoute path={HOME_PATH}>
            <Suspense fallback={<Spinner />}>
              <SwitcherRoles />
            </Suspense>
          </ProtectedRoute>
          <Route path="*">
            <Redirect to={HOME_PATH} />
          </Route>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export default App;
