import withMultiLangSelect from '@common/withMultiLangSelect';

import withFormField from '../withFormField';
import Input, { Props } from './Input';

export type InputProps = Props;
export const MultiLangInput = withMultiLangSelect<InputProps>(Input);

export const InputFormField = withFormField<
  InputProps,
  InputProps['onChange'],
  InputProps['value'],
  InputProps['onBlur']
>(Input);

export const MultiLangInputFormField = withFormField<
  InputProps,
  InputProps['onChange'],
  InputProps['value'],
  InputProps['onBlur']
>(MultiLangInput);
export default Input;
