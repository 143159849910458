/* eslint-disable import/no-cycle */
import { DateRangePickerValue } from '@common/DateRangePicker';

import { ICompanyGroup } from './companyGroups';
import { IssuerPointAPI } from './issuers';
import { IRefundPointAPI } from './refund';

export interface UsersFilter {
  id: string;
  createdAt: DateRangePickerValue | null;
  name: string;
  status: string | null;
  role: string;
  companyGroup: ICompanyGroup | null;
  username: string;
  issuers: string;
}

export interface UserForm {
  login: string;
  plainPassword: string;
  role: string;
  companyGroup: ICompanyGroup | null;
  issuers: IssuerPointAPI[];
  refundOffices: IRefundPointAPI[];
  email: string;
  phone: string;
  name: string;
  status: string;
}

export enum EUserRole {
  // Главные админки
  ROLE_SBER_TAX_FREE_ADMIN = 'ROLE_SBER_TAX_FREE_ADMIN', // Администратор
  ROLE_SBER_TAX_FREE_SUPPORT_OPERATOR = 'ROLE_SBER_TAX_FREE_SUPPORT_OPERATOR', // Оператор сопровождения
  ROLE_SBER_TAX_FREE_VOUCHER_OPERATOR = 'ROLE_SBER_TAX_FREE_VOUCHER_OPERATOR', // Оператор процессинга ваучеров
  ROLE_SBER_TAX_FREE_REFUND_OPERATOR = 'ROLE_SBER_TAX_FREE_REFUND_OPERATOR', // Оператор возмещения
  ROLE_SBER_TAX_FREE_CUSTOMS_OPERATOR = 'ROLE_SBER_TAX_FREE_CUSTOMS_OPERATOR', // Оператор ЛК таможни
  ROLE_SBER_TAX_FREE_PROCESSING_OPERATOR = 'ROLE_SBER_TAX_FREE_PROCESSING_OPERATOR', // Оператор процессинга

  // пункт возврата
  ROLE_REFUND_CASHIER = 'ROLE_REFUND_CASHIER', // Кассир пункта возврата
  ROLE_REFUND_ADMINISTRATOR = 'ROLE_REFUND_ADMINISTRATOR', // Администратор пункта возврата
  ROLE_REFUND_REGISTRY_OPERATOR = 'ROLE_REFUND_REGISTRY_OPERATOR', // Оператор процессинга реестров

  // пункт выписки
  ROLE_CLIENT_CASHIER = 'ROLE_CLIENT_CASHIER', // Кассир клиента
  ROLE_CLIENT_ADMINISTRATOR = 'ROLE_CLIENT_ADMINISTRATOR', // Администратор ОРТ
  ROLE_SBER_TAX_FREE_BOOKER = 'ROLE_SBER_TAX_FREE_BOOKER', // Оператор Бухгалтера

  // SQS Сервис быстрых Платежей
  ROLE_SQS_MODERATOR = 'ROLE_SQS_MODERATOR', // Модератор
  ROLE_SQS_MODERATOR_ADMIN = 'ROLE_SQS_MODERATOR_ADMIN', // Главный Модератор
  ROLE_SQS_AGENT = 'ROLE_SQS_AGENT', // Агент
  ROLE_SQS_CLIENT = 'ROLE_SQS_CLIENT', // Клиент
}

export enum LoginErrorType {
  NONE,
  UNAUTHORIZED,
  UNKNOWN,
  USER_DISABLED,
}
