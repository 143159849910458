import Button from '@common/Button';
import Modal from '@common/Modal';
import rootStore from '@store/index';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';

const AlertConnected: FC = observer(() => {
  const handleClose = useCallback(() => {
    rootStore.ui.closeAlert();
  }, []);

  if (!rootStore.ui.alert.isOpen) {
    // именно так, анимация закрытия мешает и лишний рендеринг
    return null;
  }

  return (
    <Modal
      isOpen
      header={rootStore.ui.alert.title || ''}
      onClose={handleClose}
      buttons={
        <Button variant="general" onClick={handleClose}>
          {rootStore.ui.alert.buttonText}
        </Button>
      }
    >
      <div style={{ wordBreak: 'break-all' }}>{rootStore.ui.alert.text}</div>
    </Modal>
  );
});

export default AlertConnected;
