import { setConfiguration } from 'react-grid-system';

import { BREAKPOINTS } from '../../consts/app';

const initGrid = (): void => {
  setConfiguration({
    defaultScreenClass: 'xs',
    breakpoints: [
      BREAKPOINTS.SM,
      BREAKPOINTS.MD,
      BREAKPOINTS.LG,
      BREAKPOINTS.XL,
      BREAKPOINTS.XL,
    ],
  });
};

export default initGrid;
