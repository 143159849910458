import { AlertProcess } from '@sberbusiness/triplex/components/Alert/AlertProcess/AlertProcess';
import { EAlertType } from '@sberbusiness/triplex/components/Alert/EAlertType';
import { LoginErrorType } from '@store/entities/users';
import rootStore from '@store/index';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

const errors: Partial<Record<LoginErrorType, string>> = {
  [LoginErrorType.NONE]: '',
  [LoginErrorType.USER_DISABLED]: 'Эта учетная запись не активна.',
  [LoginErrorType.UNAUTHORIZED]:
    'Неверный логин или пароль. Попробуйте ещё раз.',
};

const LoginError: FC = () => (
  <AlertProcess type={EAlertType.ERROR}>
    {errors[rootStore.currentUser.loginError] ||
      'Неизвестная ошибка, проверьте интернет соединение.'}
  </AlertProcess>
);

export default observer(LoginError);
