/* eslint-disable import/no-cycle */
import { EUserRole, UserForm } from '@store/entities/users';

const {
  // Главные админки
  ROLE_SBER_TAX_FREE_ADMIN, // Администратор
  ROLE_SBER_TAX_FREE_SUPPORT_OPERATOR, // Оператор сопровождения
  ROLE_SBER_TAX_FREE_VOUCHER_OPERATOR, // Оператор процессинга ваучеров
  ROLE_SBER_TAX_FREE_REFUND_OPERATOR, // Оператор возмещения
  ROLE_SBER_TAX_FREE_CUSTOMS_OPERATOR, // Оператор ЛК таможни
  ROLE_SBER_TAX_FREE_PROCESSING_OPERATOR, // Оператор процессинга

  // пункт возврата
  ROLE_REFUND_CASHIER, // Кассир пункта возврата
  ROLE_REFUND_ADMINISTRATOR, // Администратор пункта возврата

  // пункт выписки
  ROLE_CLIENT_CASHIER, // Кассир клиента
  ROLE_CLIENT_ADMINISTRATOR, // Администратор ОРТ
  ROLE_SBER_TAX_FREE_BOOKER, // Оператор Бухгалтера
} = EUserRole;

export const userFormInitialValues: UserForm = {
  email: '',
  role: '',
  companyGroup: null,
  login: '',
  plainPassword: '',
  issuers: [],
  refundOffices: [],
  phone: '',
  name: '',
  status: '',
};

export const mainManagers: EUserRole[] = [
  ROLE_CLIENT_ADMINISTRATOR,
  ROLE_SBER_TAX_FREE_ADMIN,
  ROLE_SBER_TAX_FREE_CUSTOMS_OPERATOR,
  ROLE_SBER_TAX_FREE_SUPPORT_OPERATOR,
  ROLE_SBER_TAX_FREE_REFUND_OPERATOR,
  ROLE_SBER_TAX_FREE_VOUCHER_OPERATOR,
  ROLE_SBER_TAX_FREE_PROCESSING_OPERATOR,
  ROLE_SBER_TAX_FREE_BOOKER,
];

export const operatorRoles: EUserRole[] = [
  ROLE_SBER_TAX_FREE_ADMIN,
  ROLE_SBER_TAX_FREE_PROCESSING_OPERATOR,
  ROLE_SBER_TAX_FREE_REFUND_OPERATOR,
  ROLE_SBER_TAX_FREE_SUPPORT_OPERATOR,
];

export const cashiers: EUserRole[] = [ROLE_CLIENT_CASHIER, ROLE_REFUND_CASHIER];

export const showPaymentDate = new Set([
  ROLE_REFUND_CASHIER,
  ROLE_REFUND_ADMINISTRATOR,
  ROLE_SBER_TAX_FREE_ADMIN,
  ROLE_SBER_TAX_FREE_PROCESSING_OPERATOR,
]);
export const doNotShowPaymentDate = new Set([
  ROLE_CLIENT_ADMINISTRATOR,
  ROLE_CLIENT_CASHIER,
]);
