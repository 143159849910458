/* eslint-disable no-undef */
const config = {
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  prefix: process.env.REACT_APP_API_PREFIX || '/api/v1',
  host: process.env.REACT_APP_API_HOST || '',
  endlessToken: process.env.REACT_APP_ENDLESS_TOKEN || '',
  stand: process.env.REACT_APP_STAND || 'dev',
  dadataApiKey: process.env.REACT_APP_DADATA_API_KEY || '',
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
};

export default config;
