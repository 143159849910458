/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-named-as-default-member */
import axios, { AxiosError, CancelTokenSource } from 'axios';

const { CancelToken } = axios;

interface Params {
  shouldCancelPrevRequest?: boolean;
}

class CancelableRequest {
  public static CancelByNextRequestMessage = 'cancelled by next request';

  public static CancelByOuterCallMessage = 'cancelled by outer call';

  public static isCancelled = (error: AxiosError) => axios.isCancel(error);

  public static isCancelledByNextRequest = (error: AxiosError) =>
    CancelableRequest.isCancelled(error) &&
    error.message === CancelableRequest.CancelByNextRequestMessage;

  public static isCancelledByOuterCall = (error: AxiosError) =>
    CancelableRequest.isCancelled(error) &&
    error.message === CancelableRequest.CancelByOuterCallMessage;

  public static create = <T>(
    fn: (source: CancelTokenSource, ...args: T[]) => void,
    params: Params = {},
  ): any => {
    const { shouldCancelPrevRequest = true } = params;
    let source = CancelToken.source();
    return {
      cancelByNextRequest: () => {
        // иммитация
        source.cancel(CancelableRequest.CancelByNextRequestMessage);
      },
      cancel: () => {
        source.cancel(CancelableRequest.CancelByOuterCallMessage);
      },
      fetch: (...args: T[]) => {
        if (shouldCancelPrevRequest) {
          source.cancel(CancelableRequest.CancelByNextRequestMessage);
        }
        source = CancelToken.source();
        return Promise.resolve(fn(source, ...args));
      },
    };
  };
}

export default CancelableRequest;
