/* eslint-disable import/no-cycle */
import { EOrderDirection } from '@sberbusiness/triplex/components/Tables/TableBasic/enums';

import { FiscalAccumulatorFilter } from '../../components/fiscalAccumulator/types';
import { VouchersCashierPaidFilter } from '../../containers/roles/cashierRefund/VouchersPaid/types';
import { IPaymentHistoryFilter } from './booker';
import { BrandsFilter } from './brands';
import { CashiersIssuerFilter } from './cashiersIssuer';
import { CashiersRefundFilter } from './cashiersRefund';
import { CityFilter } from './cities';
import { ICompaniesFilters } from './companies';
import { CompanyGroupFilter } from './companyGroups';
import { CustomsOfficesFilter } from './customs-offices';
import { IIntegrationsFilter } from './integrations';
import { IssuerFilter } from './issuers';
import { LogisticRegistriesFilter } from './logisticRegistries';
import { MailboxesFilter } from './mailboxes';
import { PaymentOrdersFilter } from './paymentOrders';
import { RefundFilter } from './refund';
import { RefundOfficesFilter } from './refund-offices';
import {
  RegistryFilter,
  RegistrySendFilter,
  RegistryToPayFilter,
} from './registry';
import { ShopsFilter } from './shops';
import { UsersFilter } from './users';
import { VouchersFilter } from './voucher';

// ==== QUERY STRING ==== //

export enum QUERY_PARAMS {
  limit = 'limit',
  offset = 'offset',
  sort = 'sort',
  order = 'order',
  page = '_page',
  search = 'search',
  name = 'name',
  id = 'id',
  status = 'status',
  companyGroupId = 'companyGroup.id',
  factoryNumber = 'number',
  users = 'users',
  address = 'address',
}

export enum PATH {
  searching = 'search',
  search = 'vouchers/search',
  searchPattern = 'searchPattern',
  vouchers = 'vouchers',
  company_groups = 'company_groups',
  companies = 'companies',
  fiscal_accumulators = 'fiscal_accumulators',
  users = 'users',
  issuers = 'issuers',
  refund = 'refund_offices',
  paymentHistory = 'registry_available_amounts',
  brands = 'brands',
  shops = 'shops',
  mailboxes = 'mail_boxes',
  refundOffices = 'refund_office_sites',
  customsOffices = 'checkpoints',
  registries = 'voucher_registries',
}

export type UnitPathQueryStr =
  | PATH.searching
  | PATH.search
  | PATH.searchPattern
  | PATH.vouchers
  | PATH.company_groups
  | PATH.companies
  | PATH.fiscal_accumulators
  | PATH.users
  | PATH.issuers
  | PATH.refund;

// ==== END QUERY STRING === //

// ===== TABLE ===== //

export enum TABLE_FIELD_KEY_VOUCHERS {
  id = 'id',
  createdAt = 'createdAt',
  fullName = 'fullName',
  totalAmount = 'totalAmount',
  taxationAmount = 'taxationAmount',
  refundableAmount = 'refundableAmount',
  annulmentAllowed = 'annulmentAllowed',
  status = 'status',
}

export enum TABLE_KEY_COMPANY_GROUP {
  id = 'id',
  name = 'name',
  status = 'status',
  type = 'type',
  createdAt = 'createdAt',
}

export enum TABLE_HEADER_KEY_COMPANY {
  id = 'id',
  createdAt = 'createdAt',
  name = 'name',
  company_groups = 'company_groups',
  status = 'status',
}

export enum TABLE_HEADER_KEY_FISCAL {
  id = 'id',
  number = 'number',
  companyGroup = 'companyGroup',
  status = 'status',
  createdAt = 'createdAt',
}

export enum TABLE_HEADER_USERS_KEY {
  id = 'id',
  createdAt = 'createdAt',
  name = 'name',
  role = 'role',
  contact = 'contact',
  company_groups = 'company_groups',
  status = 'status',
  issuers = 'issuers',
}

export enum TABLE_HEADER_ISSUERS_KEY {
  id = 'id',
  createdAt = 'createdAt',
  name = 'name',
  companies = 'companies',
  address = 'address',
  status = 'status',
}

export interface IPagination {
  total: number;
  rowLimit: number;
  rowNumberOptions: number[];
  currentPageNumber: number;
}

export interface ISort {
  [key: string]: EOrderDirection;
  createdAt: EOrderDirection;
  totalAmount: EOrderDirection;
  taxationAmount: EOrderDirection;
  refundableAmount: EOrderDirection;
  id: EOrderDirection;
  number: EOrderDirection;
}

// ===== END TABLE ==== //

// ==== FILTERS ===== //

export enum DIRECTION_DATA {
  startDate = 'createdAt[after]',
  finishDate = 'createdAt[before]',
}

export enum DATE_FORMAT {
  formatDate = 'YYYY-MM-DD',
  beginningTime = 'T00:00:00+03:00',
  endingTime = 'T23:59:59+03:00',
}

export interface DataPicker {
  main: UnitMainNameFilters;
  direction: UnitDirectionData;
  date: string;
  path: UnitPathQueryStr;
}

type UnitDirectionData = DIRECTION_DATA.startDate | DIRECTION_DATA.finishDate;

export enum MAIN_FILTER_NAME {
  company_groups = 'company_groups',
  companies = 'companies',
  fiscal_accumulators = 'fiscal_accumulators',
  users = 'users',
  issuers = 'issuers',
  refund = 'refund',
  vouchers = 'vouchers',
  employees = 'employees',
}

export type UnitMainNameFilters =
  | MAIN_FILTER_NAME.company_groups
  | MAIN_FILTER_NAME.companies
  | MAIN_FILTER_NAME.fiscal_accumulators
  | MAIN_FILTER_NAME.users
  | MAIN_FILTER_NAME.issuers
  | MAIN_FILTER_NAME.refund
  | MAIN_FILTER_NAME.vouchers
  | MAIN_FILTER_NAME.employees;

export interface IFilters {
  paymentOrders: PaymentOrdersFilter;
  companies: ICompaniesFilters;
  vouchersCashierPaid: VouchersCashierPaidFilter;
  registryToPay: RegistryToPayFilter;
  registry: RegistryFilter;
  registryNotPaid: RegistryFilter;
  registryCashPaid: RegistryFilter;
  registrySendPaidByCash: RegistrySendFilter;
  registrySendNotPaid: RegistrySendFilter;
  registryProcessingPaidByCash: RegistryFilter;
  registryProcessingNotPaid: RegistryFilter;
  logisticRegistries: LogisticRegistriesFilter;
  fiscalAccumulators: FiscalAccumulatorFilter;
  users: UsersFilter;
  companyGroups: CompanyGroupFilter;
  refunds: RefundFilter;
  issuers: IssuerFilter;
  vouchers: VouchersFilter;
  cashiersRefund: CashiersRefundFilter;
  cashierIssuer: CashiersIssuerFilter;
  paymentHistory: IPaymentHistoryFilter;
  brands: BrandsFilter;
  shops: ShopsFilter;
  customsOffices: CustomsOfficesFilter;
  refundOffices: RefundOfficesFilter;
  mailboxes: MailboxesFilter;
  cities: CityFilter;
  integrations: IIntegrationsFilter;
}

export enum FILTER_DATE_ERROR_TEXT {
  error = 'Дата начала периода превышает дату окончания',
}

export interface Section {
  loading: boolean;
  pagination: IPagination;
  sort: ISort;
}

export interface Sections {
  companies: Section;
  vouchersCashierPaid: Section;
  registry: Section;
  registryToPay: Section;
  registryNotPaid: Section;
  registryCashPaid: Section;
  registrySendNotPaid: Section;
  registrySendPaidByCash: Section;
  registryProcessingNotPaid: Section;
  registryProcessingPaidByCash: Section;
  logisticRegistries: Section;
  fiscalAccumulator: Section;
  paymentOrders: Section;
  users: Section;
  cashiersRefund: Section;
  cashiersIssuer: Section;
  companyGroups: Section;
  refunds: Section;
  vouchers: Section;
  issuers: Section;
  paymentHistory: Section;
  brands: Section;
  shops: Section;
  customsOffices: Section;
  refundOffices: Section;
  mailboxes: Section;
  cities: Section;
  integrations: Section;
}

// ==== END FILTERS ===== //
