import SpinnerIcon from '@common/SpinnerIcon';
import {
  isOnlyLatinRegExp,
  passportNumberRegExp,
  phoneNumberRegExp,
} from '@consts/validation';
import {
  IInputProps,
  Input as SbBolInput,
} from '@sberbusiness/triplex/components/Input/Input';
import classNames from 'classnames';
import {
  ChangeEvent,
  FC,
  FocusEvent,
  memo,
  MutableRefObject,
  useCallback,
} from 'react';

import styles from './styles.module.scss';

interface Params {
  name?: string;
}

export interface Props
  extends Omit<IInputProps, 'onChange' | 'className' | 'onBlur'> {
  onChange: (value: string, params: Params) => void;
  onBlur?: (value: string, params: Params) => void;
  className?: string;
  loading?: boolean;
  isOnlyLatin?: boolean;
  phoneNumber?: boolean;
  passportNumber?: boolean;
  isUpperCase?: boolean;
  outerInputRef?: MutableRefObject<HTMLInputElement | null>;
  disabled?: boolean;
}

const Input: FC<Props> = ({
  onChange,
  name,
  onBlur,
  className,
  loading,
  isOnlyLatin,
  isUpperCase,
  passportNumber,
  phoneNumber,
  disabled,
  ...rest
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      if (isOnlyLatin && !isOnlyLatinRegExp.test(inputValue)) {
        return;
      }
      if (phoneNumber && !phoneNumberRegExp.test(inputValue)) {
        return;
      }
      if (passportNumber && !passportNumberRegExp.test(inputValue)) {
        return;
      }
      onChange(isUpperCase ? inputValue.toUpperCase() : inputValue, { name });
    },

    [isOnlyLatin, phoneNumber, passportNumber, onChange, isUpperCase, name],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onBlur?.(e.target.value, { name });
    },
    [onBlur, name],
  );

  return (
    <div className={classNames(styles.container, className)}>
      {loading && <SpinnerIcon className={styles.spinnerWrapper} />}
      <SbBolInput
        {...rest}
        disabled={disabled}
        onChange={handleChange}
        name={name}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default memo(Input);
