/* eslint-disable import/no-cycle */
import { HttpClient } from '@services/api/http-client';
import {
  IVoucherAPICreateVoucherPayload,
  IVoucherFiscalReceiptsPayload,
} from '@services/api/types/vouchers';
import { formatRubToPenny } from '@services/utils/numbers';
import { makeAutoObservable } from 'mobx';

import {
  INewVoucherForm,
  IReceiptItem,
} from '../containers/roles/cashier/home/NewVoucherPage/types';
import type { RootStore } from './index';
import { ReceiptScanningError } from './scanner-store';

export default class VoucherStore {
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  mappingFiscalReceipts = (
    voucher: INewVoucherForm,
  ): IVoucherFiscalReceiptsPayload[] =>
    voucher.fiscalReceipts.map(
      ({
        number,
        fiscalAccumulator,
        issuedAt,
        totalAmountByFns,
        items,
      }): IVoucherFiscalReceiptsPayload => {
        const { refundableAmount, taxationAmount, totalAmount } =
          this.rootStore.genericEntities.getReceiptTotalsForm(items);
        return {
          number: number && String(number),
          fiscalAccumulator: fiscalAccumulator || null,
          type: 'income',
          company: voucher.company,
          issuedAt: issuedAt || voucher.issuedAt,
          totalAmountByFns: totalAmountByFns || null,
          items: items.map((item: IReceiptItem, index: number) => ({
            article: item.article ? item.article : null,
            label: item.name,
            pricePerItem: String(formatRubToPenny(item.pricePerItem)),
            quantity: String(item.amount),
            totalPrice: String(
              formatRubToPenny(Number(item.amount) * Number(item.pricePerItem)),
            ),
            measure: item.measure,
            vat: item.vat,
            ndsSum:
              item.ndsSum || this.rootStore.genericEntities.getNdsSum(item),
            quantityMax: String(item.amount),
            isRefundable: item.refund === '1' && !item.paymentAgentType,
            position: index,
          })),
          refundableAmount: String(formatRubToPenny(refundableAmount)),
          taxationAmount: String(formatRubToPenny(taxationAmount)),
          totalAmount: String(formatRubToPenny(totalAmount)),
        };
      },
    );

  validationReceipt = (date: string | null): boolean => {
    if (this.rootStore.scanner.toPreventFutureDate(date)) {
      return this.rootStore.scanner.setScanningError(
        ReceiptScanningError.DATE_OF_FUTURE,
      );
    }
    return false;
  };

  createVoucherPayload = (
    values: INewVoucherForm,
  ): IVoucherAPICreateVoucherPayload => {
    const total = this.rootStore.genericEntities.getAllReceiptsTotals(
      values.fiscalReceipts,
    );
    const fiscalReceipts = this.mappingFiscalReceipts(values);
    return {
      company: values.company,
      issuedAt: values.issuedAt,
      fiscalReceipts,
      consumer: {
        lastName: values.lastName.trim(),
        firstName: values.firstName.trim(),
        fatherName: values.fatherName ? values.fatherName.trim() : null,
        passport: {
          country: values.countryCode,
          number: values.passportNumber.trim(),
        },
        contact: {
          phone: values.phoneNumber.trim(),
        },
      },
      refundableAmount: String(formatRubToPenny(total.refundableAmount)),
      taxationAmount: String(formatRubToPenny(total.taxationAmount)),
      totalAmount: String(formatRubToPenny(total.totalAmount)),
      issuer: this.rootStore.currentUser.currentIssuerPoint?.id,
    };
  };

  async createVoucher(values: INewVoucherForm): Promise<string | null> {
    const payload = this.createVoucherPayload(values);
    if (this.validationReceipt(payload.issuedAt)) return null;
    return HttpClient.create<IVoucherAPICreateVoucherPayload>(
      payload,
      '/vouchers',
    )
      .then((res) => {
        this.rootStore.scanner.clear();
        return res;
      })
      .catch((e) => {
        HttpClient.log(e, {
          component: 'VoucherStore',
          functionName: 'createVoucher',
          codeLine: 108,
          requestUrl: '/vouchers',
          request: payload,
        });

        // Обработка ошибки будет в catch NewVoucherPage для того, чтобы смапить серверные ошибки
        return Promise.reject(e);
      });
  }
}
