/* eslint-disable import/no-cycle */
import { VoucherCardForm, VoucherStatus } from '../store/entities/voucher';

const {
  CREATED,
  STAMPED,
  REFUNDED_TO_CARD,
  REFUNDED_BY_CASH,
  IN_PROGRESS,
  SENT_TO_BANK,
  FOR_BANK_PAY,
  REFUSAL,
  REFUSAL_BY_BANK_REJECTED,
  REFUSAL_NO_CUSTOMS_MARK,
  REFUSAL_NO_PAYMENT_METHOD,
  REFUSAL_BY_EXPORT_PERIOD,
  REFUSAL_BY_REFUND_PERIOD,
  ERROR,
  ERROR_NO_CUSTOMS_MARK,
  ERROR_NO_PAYMENT_METHOD,
  ERROR_BANK_REJECTED,
  CANCELLED_BY_REFUND_OFFICE,
  CANCELLED_BY_OPERATOR,
  CANCELLED_BY_SHOP,
  REGISTERED,
  REJECTED_BY_FCS,
} = VoucherStatus;

export const activeVoucherInWork = new Set([CREATED, IN_PROGRESS, REGISTERED]);

export const statusesThatNeedADate = new Set([
  REFUNDED_TO_CARD,
  REFUNDED_BY_CASH,
]);

export const voucherCanRefundStatuses: VoucherStatus[] = [REGISTERED, STAMPED];
export const voucherIssuedStatuses: VoucherStatus[] = [
  CREATED,
  IN_PROGRESS,
  REGISTERED,
  STAMPED,
];
export const voucherRefundedStatuses: VoucherStatus[] = [
  REFUNDED_TO_CARD,
  REFUNDED_BY_CASH,
];
export const voucherNotRefundedStatuses: VoucherStatus[] = [
  CREATED,
  REGISTERED,
  STAMPED,
  CANCELLED_BY_SHOP,
  CANCELLED_BY_OPERATOR,
  CANCELLED_BY_REFUND_OFFICE,
  REJECTED_BY_FCS,
  IN_PROGRESS,
  REFUSAL_BY_EXPORT_PERIOD,
  REFUSAL_BY_REFUND_PERIOD,
  ERROR_NO_CUSTOMS_MARK,
  REFUSAL_NO_CUSTOMS_MARK,
  ERROR_NO_PAYMENT_METHOD,
  REFUSAL_NO_PAYMENT_METHOD,
  ERROR,
  REFUSAL,
  FOR_BANK_PAY,
  SENT_TO_BANK,
  ERROR_BANK_REJECTED,
  REFUSAL_BY_BANK_REJECTED,
];
export const voucherNotRefundedYetStatuses: VoucherStatus[] = [
  CREATED,
  REGISTERED,
  STAMPED,
  IN_PROGRESS,
  ERROR_NO_CUSTOMS_MARK,
  ERROR_NO_PAYMENT_METHOD,
  ERROR,
  FOR_BANK_PAY,
  SENT_TO_BANK,
  ERROR_BANK_REJECTED,
];
export const voucherWillNotRefundedStatuses: VoucherStatus[] = [
  CANCELLED_BY_SHOP,
  CANCELLED_BY_OPERATOR,
  CANCELLED_BY_REFUND_OFFICE,
  REJECTED_BY_FCS,
  REFUSAL_BY_EXPORT_PERIOD,
  REFUSAL_BY_REFUND_PERIOD,
  REFUSAL_NO_CUSTOMS_MARK,
  REFUSAL_NO_PAYMENT_METHOD,
  REFUSAL,
  REFUSAL_BY_BANK_REJECTED,
];
export const voucherHaveProblemStatuses: VoucherStatus[] = [
  CANCELLED_BY_SHOP,
  CANCELLED_BY_OPERATOR,
  CANCELLED_BY_REFUND_OFFICE,
  REJECTED_BY_FCS,
  REFUSAL_BY_EXPORT_PERIOD,
  REFUSAL_BY_REFUND_PERIOD,
  ERROR_NO_CUSTOMS_MARK,
  REFUSAL_NO_CUSTOMS_MARK,
  ERROR_NO_PAYMENT_METHOD,
  REFUSAL_NO_PAYMENT_METHOD,
  ERROR,
  REFUSAL,
  ERROR_BANK_REJECTED,
  REFUSAL_BY_BANK_REJECTED,
];

export const voucherPaidStatuses: VoucherStatus[] = [
  REFUNDED_TO_CARD,
  REFUNDED_BY_CASH,
];

export const registriesNotEditableStatuses: VoucherStatus[] = [
  CANCELLED_BY_SHOP,
  CANCELLED_BY_OPERATOR,
  CANCELLED_BY_REFUND_OFFICE,
  REJECTED_BY_FCS,
  REFUSAL_BY_EXPORT_PERIOD,
  REFUSAL_BY_REFUND_PERIOD,
  REFUNDED_BY_CASH,
];

export const WIDTHS = {
  padding: 10,
  row: 36,
  articleWidth: 36,
  column10: '10%',
  column20: '20%',
  column25: '25%',
  column30: '30%',
  column35: '35%',
  column40: '40%',
  column45: '45%',
  column50: '50%',
  column60: '60%',
  column70: '70%',
  column80: '80%',
  column90: '90%',
  column95: '95%',
  column100: '100%',
  column180: '180%',
  column200: '200%',
  tablet: {
    margin: '24px',
  },
};

export const voucherCardFormInitialValues: VoucherCardForm = {
  cardNumber: '',
  cardExpirationDate: '',
  phoneNumber: '',
  email: '',
  voucherIssues: [],
  issueComment: '',
  isStamped: false,
  customsCode: '',
  stampNumber: '',
  markedAt: '',
};
