/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-cycle */
import moment from 'moment';
import { inflate } from 'pako';

import { formatDateToValue } from './dates';

export enum QRCodeType {
  FNS,
  BASE64,
  OTHER,
}

export function isBase64(text: string) {
  const BASE64_REGEX = new RegExp(
    '^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$',
  );
  return BASE64_REGEX.test(text);
}

export function identifyQRCode(text: string) {
  switch (true) {
    case isBase64(text):
      return QRCodeType.BASE64;
    default:
      return QRCodeType.FNS;
  }
}

export function parseQRCode(text: string): [QRCodeType, string] {
  const type = identifyQRCode(text);
  switch (type) {
    case QRCodeType.FNS:
      return [type, text];
    case QRCodeType.BASE64:
      let result = '';
      try {
        result = inflate(
          new Uint8Array(
            atob(text || '')
              .split('')
              .map((char) => char.charCodeAt(0)),
          ),
          { to: 'string' },
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error: unknown) {
        result = '';
      }
      return [type, result];
    default:
      return [type, text];
  }
}

export interface IReceiptQRHeaderFields {
  inn: string;
  fiscalNumber: string;
  issuedAt: string;
  number: string;
  qrCodeNumber: string;
  qrCodeCount: string;
  itemCount: string;
}

export interface IReceiptQRItemFields {
  label: string;
  article: string;
  quantity: number;
  measure: string;
  vat: string;
  isRefundable: boolean;
  pricePerItem: number;
}

export const RECEIPT_QR_HEADER_MAP: Array<
  [keyof IReceiptQRHeaderFields, ((v: string) => unknown)?]
> = [
  ['inn'],
  ['fiscalNumber'],
  [
    'issuedAt',
    (value: string) => formatDateToValue(moment(value, 'DD.MM.YYYY')),
  ],
  ['number'],
  ['qrCodeNumber'],
  ['qrCodeCount'],
  ['itemCount'],
];

export const RECEIPT_QR_ITEMS_MAP: Array<
  [keyof IReceiptQRItemFields, ((v: string) => unknown)?]
> = [
  ['label'],
  ['article'],
  ['quantity'],
  ['measure', () => '1'],
  [
    'vat',
    (value: string) => (value === '10' ? '1' : value === '20' ? '2' : '3'),
  ],
  ['isRefundable', (value: string) => value === '1'],
  [
    'pricePerItem',
    (value: string) => parseFloat((parseInt(value) / 100).toFixed(2)),
  ],
];

export function parseCustomReceiptFromQR(
  data: string,
): [IReceiptQRHeaderFields, IReceiptQRItemFields[]] {
  const LINE_SEPARATOR = '\n';
  const FIELD_SEPARATOR = String.fromCharCode(0x1f);
  const lines = String(data).split(LINE_SEPARATOR);

  const handleLine = (
    line: string[],
    map: typeof RECEIPT_QR_ITEMS_MAP | typeof RECEIPT_QR_HEADER_MAP,
  ) =>
    line.map((line) =>
      line.split(FIELD_SEPARATOR).reduce((acc, element, index) => {
        const [field, parser = (v: string) => v] = map[index];
        return {
          ...acc,
          [field]: parser(element),
        };
      }, {}),
    );
  const [header] = handleLine(
    lines.slice(0, 1),
    RECEIPT_QR_HEADER_MAP,
  ) as IReceiptQRHeaderFields[];
  const items = handleLine(
    lines.slice(1),
    RECEIPT_QR_ITEMS_MAP,
  ) as IReceiptQRItemFields[];

  return [header, items];
}
