import Logo from '@common/Logo/Logo';
import { FC } from 'react';

import styles from './styles.module.scss';

const LogoWrapper: FC = () => (
  <div className={styles.logoWrapper}>
    <Logo />
  </div>
);

export default LogoWrapper;
